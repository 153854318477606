import { translateText } from '@/lang';

const Layout = () => import('@/views/layout/index.vue');
export default [
  {
    path: '/org',
    name: 'Organization',
    component: Layout,
    redirect: '/org/dept',
    children: [
      {
        path: 'dept',
        name: 'Dept',
        component: () => import('@/views/organization/dept/dept-list'),
        meta: {
          keepAlive: true,
          title: translateText('lang_auto_ec004b23_bd8a_4477_8b12_d20bebd103d4')
        }
      },
      {
        path: 'user',
        name: 'User',
        component: () => import('@/views/organization/user-list'),
        meta: {
          keepAlive: true,
          title: translateText('lang_auto_5ef99901_f352_48e9_92bf_e9f8d1953043')
        }
      },
      {
        path: 'person',
        name: 'Person',
        component: () => import('@/views/organization/person/person-list'),
        meta: {
          keepAlive: true,
          title: translateText('lang_person_management')
        }
      },
      {
        path: 'party',
        name: 'Party',
        component: () => import('@/views/organization/party/party-list'),
        meta: {
          keepAlive: true,
          title: '党组织信息'
        }
      },
      {
        path: 'company',
        name: 'Company',
        component: () => import('@/views/organization/company/company-list'),
        meta: {
          keepAlive: true,
          title: translateText(' lang_company_management')
        }
      },
      {
        path: 'duty',
        name: 'Duty',
        component: () => import('@/views/organization/duty/duty-list'),
        meta: {
          keepAlive: true,
          title: translateText('lang_auto_2350a741_c188_4393_8cad_2e6610fdda1b')
        }
      },
      {
        path: 'takePost',
        name: 'TakePost',
        component: () => import('@/views/organization/takePost/take-post-list'),
        meta: {
          keepAlive: true,
          title: '岗位管理'
        }
      },
      {
        path: 'sync',
        name: 'Sync',
        component: () => import('@/views/organization/dept-sync'),
        meta: {
          keepAlive: true,
          title: translateText('lang_auto_048a05fc_b4d8_48ff_82ec_5a8837e3bc2e')
        }
      }
    ],
    meta: {
      keepAlive: true,
      title: translateText('lang_auto_5b756d52_ea5b_4073_82b5_fed48e5ba086')
    }
  }
];
