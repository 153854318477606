import { WidgetDataSourceData, WidgetDataSourceModel, WidgetDataSourceConfigModel } from './WidgetDataSourceModel';
import { WidgetPropertiesData, WidgetPropertiesModel } from './WidgetPropertitesModel';
import { WidgetActionModel } from './WidgetActionModel';
import { PageInfoModel } from '../common/CommonModel';
import { HtmlChildModel } from './FormModel';

export interface WidgetModel {
  id?: string;
  compType: string;
  compName: string;
  compCode: string;
  displayName?: string;
  groupType: string;
  dataType: string;
  parentComponents?: WidgetModel;
  properties: WidgetPropertiesModel;
  dataSource: WidgetDataSourceModel;
  actions: WidgetActionModel[];
  dataSourceConfigs?: WidgetDataSourceConfigModel[];
  node?: HtmlChildModel;
}
export interface FormDataListModel {
  compType: string;
  dataSourceType: string;
  dataValue?: any;
  formModelCode: string;
  formModelDataType: string;
  formModelId: string;
  formModelParentId?: string;
  modelValue?: any;
  rowIndex: number | null;
  formDataId?: string;
}
export interface RequestConditionElement {
  affectedDataList: AffectedDataList;
  id: string;
  result: boolean;
}
interface AffectedDataList {
  simpleDataList?: SimpleDataListItem[];
  complexDataList?: ComplexDataListItem[];
  operation: string;
}
interface SimpleDataListItem {
  dataList: any[];
  defaultValue: {
    compType: string;
    dataValue: any;
    formModelCode: string;
    formModelDataType: string;
    formModelId: string;
    modelValue: string;
  };
  formModelCode: string;
}
export interface ComplexDataListItem {
  formModelCode: String;
  pageInfo: PageInfoModel;
}
export const WidgetData: WidgetModel = {
  id: '',
  compType: '',
  compName: '',
  compCode: '',
  groupType: '',
  dataType: '',
  properties: WidgetPropertiesData,
  dataSource: WidgetDataSourceData,
  actions: []
};

export const WidgetDataTypeDict: Record<string, string>[] = [
  // 流程参数设置的 参数类型
  {
    value: 'STRING',
    text: 'lang_character_string'
  },
  {
    value: 'NUMBER',
    text: 'lang_number'
  },
  {
    value: 'DATE',
    text: 'lang_date'
  },
  {
    value: 'COLLECTOR',
    text: 'lang_aggregate'
  },
  {
    value: 'TREE',
    text: 'lang_tree_shape'
  },
  {
    value: 'BOOLEAN',
    text: 'lang_boolean_value'
  },
  {
    value: 'FILE',
    text: 'lang_file'
  },
  {
    value: 'DEFINITION',
    text: 'lang_custom'
  }
];

export const WidgetDataTypeEnum: Record<string, string> = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  COLLECTOR: 'COLLECTOR',
  TREE: 'TREE',
  BOOLEAN: 'BOOLEAN',
  FILE: 'FILE',
  DEFINITION: 'DEFINITION'
};

export const WidgetLogicEnum: Record<string, string> = {
  EQ: 'EQ',
  NE: 'NE',
  GT: 'GT',
  LT: 'LT',
  GE: 'GE',
  LE: 'LE',
  INC: 'INC',
  NINC: 'NINC',
  BELONG: 'BELONG',
  NBELONG: 'NBELONG'
};

export const WIDGET_TYPE_MAPS: any = {
  CARD: 'card',
  GRID: 'row',
  TABLE: 'table',
  DIALOG_LIST: 'dialogList',
  IMAGE: 'image',
  UPLOAD: 'upload'
};

export const LAYOUT_WIDGET_KEYS: any[] = ['card', 'row', 'table', 'dialog'];
