import HttpUtil from '@/utils/HttpUtil';

// 获取部门公司分页数据
export const getDeptCompanyPage = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/query/group/page';
  return HttpUtil.post(url, params);
};

// 获取登录人的公司信息
export const getUserCompany = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/getCompany';
  return HttpUtil.get(url, params);
};

// 获取人员的公司信息
export const getCompanys = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/getCompanys';
  return HttpUtil.get(url, params);
};
// 获取人员的公司信息
export const getCompanysByPersonId = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/getCompanysByPersonId';
  return HttpUtil.get(url, params);
};

// 获取部门列表
export const getDeptListData = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/query/organization/list';
  return HttpUtil.post(url, params);
};

// 获取公司树
export const getCompanyTreeData = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/query/group/tree';
  return HttpUtil.post(url, params);
};

// 获取公司分页列表
export const getCompanyPage = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/company/query/page';
  return HttpUtil.post(url, params);
};

// 新增编辑公司
export const saveCompany = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/company/save';
  return HttpUtil.post(url, params);
};

// 获取公司类型
export const getCompanyType = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/company/query/companyTypes';
  return HttpUtil.get(url, params);
};

// 获取组织机构树
export const getDeptTreeData = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/query/organization/tree';
  return HttpUtil.get(url, params);
};

// 获取职务列表
export const getDutyListData = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/query/duty/list';
  return HttpUtil.post(url, params);
};

// 获取机构组树列表
export const getOrgGroupTreeData = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/query/orgGroup/tree';
  return HttpUtil.post(url, params);
};

// 获取机构组列表
export const getOrgGroupListData = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/query/orgGroup/list';
  return HttpUtil.post(url, params);
};

// 创建机构组
export const createOrgGroup = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/createOrgGroup';
  return HttpUtil.post(url, params);
};

// 修改机构组
export const updateOrgGroup = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/updateOrgGroup';
  return HttpUtil.post(url, params);
};

// 删除机构组
export const deleteOrgGroup = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/deleteOrgGroup';
  return HttpUtil.get(url, params);
};

// 获取机构组详情
export const detailsOrgGroup = (groupId?: string) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/group/detailsOrgGroup';
  return HttpUtil.get(url, { groupId });
};

// 添加选中机构到机构组
export const updateOrgGroupLink = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/link/updateOrgGroupLink';
  return HttpUtil.post(url, params);
};

// 获取机构组下选中的机构
export const getOrgGroupLinks = (groupId: string) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/link/getOrgGroupLinks';
  return HttpUtil.get(url, { groupId });
};

/**
 * description
 * @param { tenantId } param 租户id， 非必填
 * @param { parendId } param 所属组织id
 * @returns { array }
 */
export const getChild = params => {
  var url = process.env.VUE_APP_SERVER_CODE + 'id/group/query/organization/children';
  return HttpUtil.get(url, params);
};

// 同步部门
export const syncOrg = (params: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/trp/sync/all';
  return HttpUtil.get(url, params);
};
