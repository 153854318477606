import Vue from "vue"
import Vuex from "vuex"
import CommonStore from '@/store/modules/CommonStore'
import FormStore from '@/store/modules/FormStore'
import FlowStore from '@/store/modules/FlowStore'
import HomeStore from "@/store/modules/HomeStore"
Vue.use(Vuex)
const StoreIndex = new Vuex.Store({
  modules: {
    common: CommonStore,
    form: FormStore,
    flow: FlowStore,
    home: HomeStore
  },
})

export default StoreIndex
