import HttpUtil from '@/utils/HttpUtil';
/*
 *@description: 表单设计页面 获取表单配置数据
 *@author: gongcaixia
 *@date: 2021-05-09 10:48:49
 */

export const findAllFormConf = (params?: any) => {
  let url = '/river-system/form/model/query/list';
  return HttpUtil.post(url, params);
};

/*
 *@description: 保存表单配置信息
 *@author: gongcaixia
 *@date: 2021-05-17 09:50:31
 */
export const saveFormConfigsData = (params?: any) => {
  let url = '/river-system/form/save';
  return HttpUtil.post(url, params);
};

/*
 *@description: 表单分页列表分页
 *@author: gongcaixia
 *@date: 2021-05-17 09:50:31
 */
export const getFormPage = (params?: any) => {
  let url = '/river-system/form/query/page';
  return HttpUtil.post(url, params);
};
/*
 *@description: 表单分页列表分页
 *@author: gongcaixia
 *@date: 2021-05-17 09:50:31
 */
export const getFormList = (params?: any) => {
  let url = '/river-system/form/query/list';
  return HttpUtil.post(url, params);
};

/**
 * 获取已发布的表单tree
 * @param {any} param
 * @returns {any}
 */
export const getPublishFormTree = (params?: any) => {
  let url = '/river-system/application/link/query/code/tree?linkType=1';
  return HttpUtil.get(url, params);
};

/**
 * 获取已发布的流程tree
 * @param {any} param
 * @returns {any}
 */
export const getPublishProcessTree = (params?: any) => {
  let url = '/river-system/application/link/query/code/tree?linkType=2';
  return HttpUtil.get(url, params);
};

export const getFormPropTree = (params?: any) => {
  let url = '/river-system/form/model/column/tree';
  return HttpUtil.get(url, params);
};

export const getWidgetConditions = (params?: any) => {
  let url = '/river-system/form/model/conditions';
  return HttpUtil.get(url, params);
};

/*
 *@description: 根据表单id 或者 code+version 获取表单详情
 *@author: gongcaixia
 *@date: 2021-05-17 09:52:15
 */
export const getFormConfigsData = (id: string) => {
  return getFormConfig({ id: id });
};

export const getBusinessDataSource = (params?: any) => {
  let url = `/river-system/config/data/valueItems`;
  return HttpUtil.post(url, params);
};

/*
 *@description: 数据源筛选条件 树形或者集合时获取 valueItems
 *@author: gongcaixia
 *@date: 2021-05-20 14:30:28
 */
export const getValueItemsData = (params?: any) => {
  let url = `/river-system/form/model/query/valueItems`;
  return HttpUtil.post(url, params);
};

/*
 *@description: 数据源筛选条件 树形或者集合时获取 valueItems
 *@author: gongcaixia
 *@date: 2021-05-20 14:30:28
 */
export const getFunctions = (params?: any) => {
  let url = `/river-system/config/functions`;
  return HttpUtil.post(url, params);
};

export const getCompares = (params?: any) => {
  let url = `/river-system/config/compares/mapping`;
  return HttpUtil.post(url, params);
};

/**
 * description  获取表单模型保存之后的 表单数据
 * @param {string} id
 * @param {string} code
 * @param {number} version
 * @returns {}
 */
export const getFormConfig = params => {
  let url = `/river-system/form/query/single`;
  return HttpUtil.get(url, params);
};

export const getFormConfigByCode = (params: any) => {
  let url = '/river-system/form/query/single/byCode';
  return HttpUtil.get(url, params);
};

/**
 * description  获取表单中 下拉的数据
 * @param {string} id
 * @returns {}
 */
export const getSelectDatas = (id: string, params?: any) => {
  let url = `/river-system/form/query/data?id=${id}`;
  return HttpUtil.get(url, params);
};

/**
 * description  获取表单数据
 * @param {string} formCode
 * @param {} page
 * @returns []
 */
export const getFormData = (params?: any) => {
  let url = `/river-system/form/data/rows`;
  return HttpUtil.post(url, params);
};

/**
 * description  获取组件对应数据源配置的表单数据
 * @param {string} formCode
 * @param {} page
 * @returns []
 */
export const getFormDataByWidget = (params?: any) => {
  let url = `/river-system/form/data/modelRows`;
  return HttpUtil.post(url, params);
};

/**
 * description  保存已填写好的表单
 * @param {string} id
 * @returns {}
 */
export const saveFormData = (params?: any) => {
  let url = `/river-system/form/data/save`;
  return HttpUtil.post(url, params);
};

/**
 * description  保存已填写好的表单
 * @param {string} id
 * @returns {}
 */
export const deleteFormData = (params?: any) => {
  let url = `/river-system/form/data/delete`;
  return HttpUtil.get(url, params);
};

/**
 * 根据表单所有组件的数据源
 * @param {number} param
 * @returns {number}
 */
export const getAllWidgetsDefaultData = (params: any) => {
  let url = `/river-system/form/model/query/initial/data`;
  return HttpUtil.post(url, params);
};

/**
 * 根据表单所有组件的数据源
 * @param {number} param
 * @returns {number}
 */
export const getAllWidgetsHistoryData = (params: any) => {
  let url = `/river-system/form/model/query/data/list`;
  return HttpUtil.post(url, params);
};

/**
 * 根据组件的数据源配置获取组件的选项
 * @param {any} param
 * @returns {number}
 */
export const getWidgetDataSource = (params: any) => {
  let url = `/river-system/form/model/query/data/components`;
  return HttpUtil.post(url, params);
};

/**
 * 根据表单所有组件的数据源
 * @param {number} param
 * @returns {number}
 */
export const getAffectWidgetDataSource = (params: any) => {
  let url = `/river-system/form/model/query/data/affected/components`;
  return HttpUtil.post(url, params);
};
interface RequestConditionModel {
  formId: string;
  formDataId: string;
  formModelId: string;
  formModelDataList: any[];
}
export const validWidgetCondition = (params: RequestConditionModel) => {
  let url = '/river-system/form/model/trigger/result';
  return HttpUtil.post(url, params);
};

/**
 * description  获取表单所有简单类型的下拉的数据
 * @param {string} id
 * @returns {}
 */
export const getSimpleDataSource = (params: any) => {
  let url = `/river-system/form/model/query/data/simple/list`;
  return HttpUtil.post(url, params);
};

/**
 * description  获取表单所有复杂类型的下拉的数据
 * @param {string} id
 * @returns {}
 */
export const getComplexDataSource = (params: any) => {
  let url = `/river-system/form/model/query/data/complex/list`;
  return HttpUtil.post(url, params);
};

export const getWidgetComplexDataSource = (params: any) => {
  let url = `/river-system/form/model/query/data/complex/components`;
  return HttpUtil.post(url, params);
};

export const getWidgetSimpleDataSource = (params: any) => {
  let url = `/river-system/form/model/query/data/simple/components`;
  return HttpUtil.post(url, params);
};

export const getAffectedWidgetData = (params: any) => {
  let url = `/river-system/form/model/query/data/simple/affected/components`;
  return HttpUtil.post(url, params);
};

export const getAffectedWidgetComplexData = (params: any) => {
  let url = `/river-system/query/data/complex/affected/components`;
  return HttpUtil.post(url, params);
};

/**
 * description  获取表单中 所有的下拉的数据
 * @param {string} id
 * @returns {}
 */
export const getModelDataById = (id: string) => {
  let url = `/river-system/form/model/query/data/by/id?id=${id}`;
  return HttpUtil.get(url);
};

/*
 *@description: 表单分页列表分页
 *@author: gongcaixia
 *@date: 2021-05-17 09:50:31
 */
export const getDataInstancePage = (params: any) => {
  let url = `/river-system/form/data/page`;
  return HttpUtil.post(url, params);
};
/*
 *@description: 数据源配置获取默认值 选项数据源
 *@author: gongcaixia
 *@date: 2021-05-17 09:50:31
 */
export const getDefaultDatas = (params: any) => {
  let url = `/river-system/form/model/query/data/by/components`;
  return HttpUtil.post(url, params);
};

/*
 *@description: 表单发布
 *@author: zpc
 */
export const changeFormStatus = (params: any) => {
  let url = `/river-system/form/change/status`;
  return HttpUtil.post(url, params);
};

/**
 * description  获取表单历史版本
 * @param {}
 * @returns {}
 */
export const getHistoryForm = (lid: string) => {
  let url = '/river-system/application/link/query/all/version';
  return HttpUtil.get(url, { id: lid });
};

/**
 * description  升版
 * @param {}
 * @returns {}
 */
export const formUpgrade = (id: string) => {
  let url = `/river-system/form/upgrade?id=${id}`;
  return HttpUtil.get(url);
};

/**
 * description  数据实例详情
 * @param {}
 * @returns {}
 */
export const getDataInstanceDetail = (params: any) => {
  let url = '/river-system/form/data/dataList';
  return HttpUtil.get(url, params);
};

/**
 * description  获取各数据源的列信息
 * @param {}
 * @returns {}
 */
export const getDataSourceColumns = (params: any) => {
  let url = '/river-system/condition/value/column/valueItems';
  return HttpUtil.post(url, params);
};

/**
 * description  获取各数据源的结构信息
 * @param {}
 * @returns {}
 */
export const getWidgetConfigStruct = (params: any) => {
  let url = '/river-system/config/global/list';
  return HttpUtil.post(url, params);
};

/**
 * description  获取各数据源的结构信息
 * @param {}
 * @returns {}
 */
export const getDataSourceMapping = (params: any) => {
  let url = '/river-system/config/global/mapping';
  return HttpUtil.post(url, params);
};

/**
 * description  删除应用表单
 * @param id 表单id
 * @returns {}
 */
export const deleteAppForm = (id: string) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'form/delete';
  return HttpUtil.get(url, { id });
};
