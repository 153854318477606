import { translateText } from '@/lang';

const Layout = () => import('@/views/layout/index.vue');
const FileView = () => import('@/views/document/document-detail.vue');
export default [
  {
    path: '/data',
    name: 'Data',
    component: Layout,
    children: [
      {
        path: 'task',
        name: 'Task',
        component: () => import('@/views/data-manage/task-list'),
        meta: {
          keepAlive: true,
          title: translateText('lang_auto_0829c36e_21ec_4ec9_91ed_615525d6c524')
        }
      },
      {
        path: 'form',
        name: 'FormDataList',
        component: () => import('@/views/data-manage/form-data-list'),
        meta: {
          keepAlive: true,
          title: translateText('lang_auto_a502c794_466c_4408_8b65_633ec47eb6b7')
        }
      },
      {
        path: 'report',
        name: 'Report',
        component: () => import('@/views/data-manage/report-list'),
        meta: {
          keepAlive: true,
          title: translateText('lang_auto_8adc21f2_80b2_4875_9001_17296fee1e15')
        }
      },
      {
        path: 'log',
        component: () => import('@/views/home/logs/index'),
        meta: {
          keepAlive: true,
          title: '印控日志管理'
        }
      },
    ],
    meta: {
      keepAlive: true,
      title: translateText('lang_application_management')
    }
  },

  // 文件预览地址
  {
    path: '/fileView',
    name: 'FileViewDetail',
    component: FileView,
    meta: {
      keepAlive: true,
      title: translateText('lang_document_detail')
    }
  },

  //文档管理地址
  {
    path: '/documentView',
    name: 'DocumentDetail',
    component: () => import('@/views/document/document-list-view'),
    meta: {
      keepAlive: true,
      title: translateText('lang_document_management')
    }
  },
  // 更多应用
  {
    path: '/applicationList',
    name: 'ApplicationList',
    component: () => import('@/views/document/application-list'),
    meta: {
      keepAlive: true,
      title: '更多应用'
    }
  },
  {
    path: '/usuallyApplication',
    name: 'UsuallyApplication',
    component: () => import('@/views/usually-application/usually-application'),
    meta: {
      keepAlive: true,
      title: '常用系统列表'
    }
  },
  {
    path: '/usuallyViwer',
    name: 'UsuallyViwer',
    component: () => import('@/views/usually-application/usually-viwer'),
    meta: {
      keepAlive: true,
      title: '常用系统预览'
    }
  },

  /******************************************************************
   *流程更多
   ******************************************************************/
  //待办列表
  {
    path: '/waitListView',
    name: 'WaitListView',
    component: () => import('@/views/front-frame/front-wait-list2.0'),
    meta: {
      keepAlive: true,
      title: translateText('lang_to_do_list')
    }
  },

  //已办
  {
    path: '/doneListView',
    name: 'DoneListView',
    component: () => import('@/views/front-frame/front-done-list2.0'),
    meta: {
      keepAlive: true,
      title: translateText('lang_done_items')
    }
  },

  //预知列表
  {
    path: '/possibilityListView',
    name: 'PossibilityListView',
    component: () => import('@/views/front-frame/front-possibility-list2.0'),
    meta: {
      keepAlive: true,
      title: translateText('lang_predicted_events')
    }
  },

  //发起列表
  {
    path: '/startedListView',
    name: 'StartedListView',
    component: () => import('@/views/front-frame/front-started-list2.0'),
    meta: {
      keepAlive: true,
      title: translateText('lang_initiating_matters')
    }
  },

  //结办列表
  {
    path: '/endView',
    name: 'EndView',
    component: () => import('@/views/flow-list/flow-end'),
    meta: {
      keepAlive: true,
      title: translateText('lang_auto_f0a07f68_fa3a_48bb_aa14_3dd3f15d758b')
    }
  },

  //抄送列表
  {
    path: '/ccView',
    name: 'CcView',
    component: () => import('@/views/flow-list/flow-cc'),
    meta: {
      keepAlive: true,
      title: translateText('lang_auto_e8a35597_cd29_4dac_bedf_272cc1b8aabb')
    }
  },

  //发起列表
  {
    path: '/delegateView',
    name: 'DelegateView',
    component: () => import('@/views/flow-list/flow-delegate'),
    meta: {
      keepAlive: true,
      title: translateText('lang_auto_aa441227_b368_4080_9529_dc0ab51d1d76')
    }
  }
];
