import { GraphConfigData } from '@logicflow/core';

export interface FlowGraphModel extends GraphConfigData {}

export function FlowGraphData(): FlowGraphModel {
  return {
    edges: [],
    nodes: []
  };
}

export const GraphColor: any = {
  DEFAULT_NODE_COLOR: '#57a3f3',
  EXCUTED_NODE_COLOR: '#33aaaa',
  ARRIVING_NODE_COLOR: '#dd8877'
};
