







import { Component, Vue } from 'vue-property-decorator';
import GlbLoading from '@/components/glb-loading/glb-loading.vue';
import _ from 'lodash';
import langDialog from '@/components/lang-config/dialog.vue';
import DateUtil from '@/utils/DateUtil';
import TokenUtil from 'global-ui/packages/utils/TokenUtil';
import '@/utils/easy-pop/easyPop';

@Component({
  name: 'App',
  components: { GlbLoading, langDialog }
})
export default class App extends Vue {
  get _pageLoading() {
    return this.$store.getters.pageLoading;
  }

  handleWindowResize() {
    let screenWidth = document.body.clientWidth;
    let screenHeight = document.body.clientHeight;
    this.$store.commit('SET_CLIENT_WIDTH', screenWidth);
    this.$store.commit('SET_CLIENT_HEIGHT', screenHeight);
  }

  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  mounted() {
    let screenWidth = document.body.clientWidth;
    let screenHeight = document.body.clientHeight;
    this.$store.commit('SET_CLIENT_WIDTH', screenWidth);
    this.$store.commit('SET_CLIENT_HEIGHT', screenHeight);
    window.addEventListener('resize', () => {
      this.handleWindowResize;
    });
    this.connect();
  }
  /**-----------------------------------------------
   * ---------- webSocket 右侧通知------------------
   * -----------------------------------------------
   */
  // 链接
  connect() {
    if ((window as any)._easypop) {
      let easyPop = (window as any)._easypop;
      let timer = setInterval(() => {
        if (easyPop.link) {
          easyPop.link.addEventListener('message', e => {
            let data = JSON.parse(e.data);
            switch (data.noticeType) {
              case 'task_runtime':
                this.showMessage(data, data.noticeType);
                break;
              case 'task_time_out':
                this.showMessage(data, data.noticeType);
                break;
              case 'task_due_date':
                this.showMessage(data, data.noticeType);
                break;
              case 'task_end':
                this.showMessage(data, data.noticeType);
                break;
            }
          });
          clearInterval(timer);
        }
      }, 200);
    }
  }
  // 展示卡片
  showMessage(data, type) {
    const h = this.$createElement;
    let d = new Date(data.notice.CREATE_DATE);
    this.$notify({
      title: data.notice.TITLE,
      duration: 4500,
      position: 'top-right',
      offset: 100,
      customClass: 'notice-style',
      onClick: this.jumpItem.bind(this, data.notice, type),
      message: h(
        'div',
        {
          /* on: {
              click: this.jumpItem.bind(this, data.notice, type)
            }, */
          staticClass: 'notice-hover'
        },
        [
          h('div', `${data.notice.CREATE_USER_NAME}`),
          h('div', { class: 'notice-content-date' }, [h('div', `${DateUtil.format(d, 'yyyy-MM-dd')}`)])
        ]
      )
    });
  }
  // 跳转
  jumpItem(item, type) {
    let url;
    if (type !== 'task_end') {
      url = process.env.VUE_APP_SERVER_CODE + 'task/redirect/undo';
    } else {
      url = process.env.VUE_APP_SERVER_CODE + 'task/redirect/end';
    }
    let params = '?id=' + item.ID + '&token=' + TokenUtil.getToken();
    window.location.href = url + params;
  }
}
