import { translateText } from '@/lang';

const Layout = () => import('@/views/layout/index.vue');
const DesignerLayout = () => import('@/views/layout/designer.vue');
export default [
  {
    path: '/application',
    name: 'AppMdu',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'Application',
        component: () => import('@/views/application/app-list-style1.0'),
        meta: {
          keepAlive: true,
          title: 'lang_auto_83d9741b_2f90_4faf_a994_99dc887a2b4c'
        }
      },
      {
        path: 'document',
        name: 'Document',
        component: () => import('@/views/document/document-list'),
        meta: {
          keepAlive: true,
          title: 'lang_document_management'
        }
      },
      {
        path: '/document/detail',
        name: 'Detail',
        component: () => import('@/views/document/document-detail'),
        meta: {
          keepAlive: true,
          title: 'lang_document_detail'
        }
      },
      {
        path: 'list2',
        name: 'Application',
        component: () => import('@/views/application/app-list'),
        meta: {
          keepAlive: true,
          title: 'lang_auto_83d9741b_2f90_4faf_a994_99dc887a2b4c'
        }
      },
      /*  {
            path: "template",
            name: "AppTemplate",
            component: () => import("@/views/application/template"),
            meta: {
              keepAlive: true,
              title: 'lang_auto_96b9d7c3_1baa_448f_b029_f15f8a016eea'
            }
          } ,*/

      {
        path: 'detail',
        name: 'AppDetail',
        component: () => import('@/views/application/app-detail'),
        meta: {
          keepAlive: true,
          title: 'lang_application_details'
        }
      }
    ],
    meta: {
      keepAlive: true,
      title: 'lang_auto_4f3a4a02_1767_44f7_8bf7_f726f73fa90d'
    }
  },
  {
    path: '/form',
    name: 'FormModule',
    component: Layout,
    redirect: '/form/list',
    children: [
    {
      path: 'history',
      name: 'FormHistory',
      component: () => import('@/views/form-design/form-history'),
      meta: {
        keepAlive: true,
        title: 'lang_historical_version'
      }
    }],
    meta: {
      keepAlive: true,
      title: 'lang_form_configuration'
    }
  },

  {
    path: '/flow',
    name: 'FlowModule',
    component: Layout,
    redirect: '/flow/list',
    children: [
    {
      path: 'history',
      name: 'FlowHistory',
      component: () => import('@/views/flow-design/flow-history'),
      meta: {
        keepAlive: true,
        title: 'lang_historical_version'
      }
    }],
    meta: {
      keepAlive: true,
      title: 'lang_process_configuration'
    }
  },
  {
    path: '/flowInit',
    name: 'flowInitCreate',
    component: Layout,
    redirect: '/flowInit/create',
    children: [
    {
      path: 'create',
      name: 'FlowInit',
      component: () => import('@/views/flow-deal/flow-init'),
      meta: {
        keepAlive: true,
        title: 'lang_new_process'
      }
    }],
    meta: {
      keepAlive: true,
      title: 'lang_new_process'
    }
  },
  {
    path: '/flow/design',
    name: 'FlowDesign',
    component: () => import('@/views/flow-design/flow-designer'),
    meta: {
      keepAlive: true,
      title: 'lang_application_process_design'
    }
  },
  {
    path: '/flow/start',
    name: 'FlowStart',
    component: () => import('@/views/flow-deal/flow-start'),
    meta: {
      keepAlive: true,
      title: 'lang_process_start'
    }
  },
  {
    path: '/flow/undo',
    name: 'FlowUndo',
    component: () => import('@/views/flow-deal/flow-undo'),
    meta: {
      keepAlive: true,
      title: 'lang_to_do_list'
    }
  },
  {
    path: '/flow/possibility',
    name: 'FlowPossibility',
    component: () => import('@/views/flow-deal/flow-possibility'),
    meta: {
      keepAlive: true,
      title: 'lang_predicted_events'
    }
  },
  {
    path: '/flow/started',
    name: 'FlowStarted',
    component: () => import('@/views/flow-deal/flow-request'),
    meta: {
      keepAlive: true,
      title: 'lang_initiating_matters'
    }
  },
  {
    path: '/flow/done',
    name: 'FlowDone',
    component: () => import('@/views/flow-deal/flow-done'),
    meta: {
      keepAlive: true,
      title: 'lang_done_items'
    }
  },
  {
    path: '/flow/finish',
    name: 'FlowFinish',
    component: () => import('@/views/flow-deal/flow-finish'),
    meta: {
      keepAlive: true,
      title: 'lang_matters_to_be_settled'
    }
  },
  {
    path: '/flow/cc',
    name: 'FlowCarbon',
    component: () => import('@/views/flow-deal/flow-cc'),
    meta: {
      keepAlive: true,
      title: '抄送事项'
    }
  },
  {
    path: '/flow/delegate',
    name: 'FlowDelegate',
    component: () => import('@/views/flow-deal/flow-delegate'),
    meta: {
      keepAlive: true,
      title: '转办事项'
    }
  },

  {
    path: '/form/design',
    name: 'FormDesign',
    component: () => import('@/views/form-design/form-designer-con'),
    meta: {
      keepAlive: true,
      title: 'lang_application_form_design'
    }
  },
  {
    path: '/flow/more',
    name: 'flowMore',
    component: Layout,
    redirect: '/flow/more/undo',
    children: [
    {
      path: 'undo',
      name: 'HomeMoreFlowUndo',
      component: () => import('@/views/flow-list/flow-undo'),
      meta: {
        keepAlive: true,
        title: 'lang_auto_a583428a_ddbc_4dc1_97c5_03b92cdb4279'
      }
    },
    {
      path: 'start',
      name: 'HomeMoreFlowStart',
      component: () => import('@/views/flow-list/flow-start'),
      meta: {
        keepAlive: true,
        title: 'lang_auto_4c4351d7_1d8b_4bff_8e8b_c93445a18a1c'
      }
    },
    {
      path: 'done',
      name: 'HomeMoreFlowDone',
      component: () => import('@/views/flow-list/flow-done'),
      meta: {
        keepAlive: true,
        title: 'lang_auto_99963bc6_74b5_4ebf_a555_7679fe4f39ee'
      }
    },
    {
      path: 'end',
      name: 'HomeMoreFlowEnd',
      component: () => import('@/views/flow-list/flow-end'),
      meta: {
        keepAlive: true,
        title: 'lang_auto_f0a07f68_fa3a_48bb_aa14_3dd3f15d758b'
      }
    },
    {
      path: 'cc',
      name: 'HomeMoreFlowCc',
      component: () => import('@/views/flow-list/flow-cc'),
      meta: {
        keepAlive: true,
        title: 'lang_auto_e8a35597_cd29_4dac_bedf_272cc1b8aabb'
      }
    },
    {
      path: 'delegate',
      name: 'HomeMoreFlowDelegate',
      component: () => import('@/views/flow-list/flow-delegate'),
      meta: {
        keepAlive: true,
        title: 'lang_auto_aa441227_b368_4080_9529_dc0ab51d1d76'
      }
    },
    {
      path: 'possibility',
      name: 'HomeMoreFlowPossibility',
      component: () => import('@/views/flow-list/flow-possibility'),
      meta: {
        keepAlive: true,
        title: 'lang_auto_0ddf0dab_c8d2_4186_b771_1344a446467d'
      }
    }],
    meta: {
      keepAlive: true,
      title: 'lang_auto_7dfea5aa_ff31_42ca_98be_6f3e50327fdc'
    }
  },
  {
    path: '/printer/design',
    name: 'FormPrinterDesign',
    component: () => import('@/views/form-design/form-printer'),
    meta: {
      keepAlive: true,
      title: 'lang_print_configuration'
    }
  },
];