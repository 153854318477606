const Layout = () => import('@/views/layout/index.vue');
export default [
{
  path: '/delegation',
  name: 'Delegation',
  component: Layout,
  children: [
  {
    path: 'entrust',
    name: 'Entrust',
    component: () => import('@/views/delegation/entrust'),
    meta: {
      keepAlive: true,
      title: 'lang_entrust_mng'
    }
  }, ],
  meta: {
    keepAlive: true,
    title: 'lang_delegation_mng'
  }
}, ];