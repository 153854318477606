import { buildVariableTree, buildWidgetTreeForDataSource, buildFormWidgetTree, buildFormWidgetMap } from '@/components/form-design/utils';
import { WidgetData } from '@/models/form/WidgetModel';
import { WidgetDataSourceConfigTypeEnum } from '@/models/form/WidgetDataSourceModel';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
const form = {
  state: {
    formConfigs: {},
    businessDataSource: [],
    activeWidget: SystemUtil.cloneDeep(WidgetData),
    functions: [],
    compares: [],
    allSave: 1, //表单预览时,点击保存触发
    widgetConfigStruct: {}, // 组件配置结构（数据源配置项、默认值配置项）
    widgetConfigMapping: {} // 组件可配置数据源
  },
  mutations: {
    SET_FORM_CONFIG(state, data) {
      let formConfigs = {
        ...state.formConfigs,
        [data.id]: data
      };
      state.formConfigs = formConfigs;
    },
    SET_FORM_CONFIG_IMG_FILE_ID(state, data) {
      state.formConfigs[data.formId].container.components.map(item => {
        if (item.id == data.data.id) {
          item = data.data;
        }
      });
    },

    SET_BUSINESS_DATA_SOURCE(state, data) {
      state.businessDataSource = data;
    },
    SET_ACTIVED_WIDGET(state, data) {
      state.activeWidget = data;
    },
    SET_FORM_FUNCTIONS(state, data) {
      state.functions = data;
    },
    SET_FORM_COMPARES(state, data) {
      state.compares = data;
    },
    SET_WIDGET_CONFIG_STRUCT(state, data) {
      state.widgetConfigStruct = data;
    },

    SET_DATASOURCE_MAPPING(state, data) {
      state.widgetConfigMapping = data;
    },
    SET_ALL_SAVE(state, data) {
      if (state.allSave > 100) {
        state.allSave = 1;
      } else {
        state.allSave++;
      }
    }
  },
  getters: {
    /**
     * @description: 获取当前表单配置
     * @return:
     */
    formConfigs: state => {
      return state.formConfigs;
    },

    // 获取当前选中的组件
    activeWidget: state => {
      return state.activeWidget;
    },

    // 配置数据源时，获取数据源的值的所有选项
    dataSourceMaps: state => {
      let result = {};
      let formConfigs = state.formConfigs;
      for (const key in formConfigs) {
        let formConfig = formConfigs[key];
        if (formConfig && formConfig.container && formConfig.container.components && formConfig.container.components.length) {
          let variableTree = buildVariableTree(formConfig.container.paramsOptions);
          let widgetTree = buildWidgetTreeForDataSource(formConfig.container.components);
          let businessDataTree = {
            valueType: 'FIXED_VALUE',
            data: state.businessDataSource
          };
          result[formConfig.id] = [variableTree, widgetTree, businessDataTree];
        }
      }
      return result;
    },

    // 配置表达式时，获取可用的函数
    functions: state => {
      return state.functions;
    },

    // 配置表达式时，获取可用的逻辑运算符
    compares: state => {
      return state.compares;
    },

    // 获取数据源的结构
    dataSourceStruct: state => {
      let result = {};
      let dataSourceConfigs = state.widgetConfigStruct.dataSourceConfigs;
      let realityGlobalDetails = state.widgetConfigStruct.realityGlobalDetails;
      dataSourceConfigs.map(item => {
        let reality = realityGlobalDetails.find(realityItem => item.reality == realityItem.reality);
        let tmp = {
          name: item.name,
          type: item.type,
          reality: item.reality
        };
        if (item.columnConfig) {
          tmp.complexColumns = reality.columns || [];
          tmp.simpleColumn = item.columnConfig.customSimpleColumn || [];
        }

        if (item.conditionConfig) {
          tmp.dataConditions = reality.dataConditions || [];
        }
        // zaa新增  用于列表组件
        if (item.conditionValueConfigs) {
          tmp.conditionValueConfigs = item.conditionValueConfigs || [];
        }

        if (item.type == WidgetDataSourceConfigTypeEnum.PROCESS_TASK_LOG_CONFIG) {
          tmp.processCode = item.processCode;
          tmp.lastNode = item.lastNode;
          tmp.lastOperator = item.lastOperator;
        }
        result[item.type] = tmp;
      });
      return result;
    },

    widgetRealitysMap: state => {
      let result = {};
      state.widgetConfigStruct.realityGlobalDetails.forEach(item => {
        result[item.reality] = item;
      });
      return result;
    },

    // 默认值类型
    defaultDataStruct: state => {
      let result = {};
      state.widgetConfigStruct.defaultDataConfigs.forEach(item => {
        result[item.type] = item;
      });
      return result;
    },

    // 触发器类型
    actionStruct: state => {
      let result = {};
      state.widgetConfigStruct.actions.forEach(item => {
        result[item.type] = item;
      });
      return result;
    },

    // 获取数据源的结构
    widgetConfigMapping: state => {
      return state.widgetConfigMapping;
    },
    // 表单项树
    formWidgetTree: state => {
      let result = {};
      let formConfigs = state.formConfigs;
      for (const key in formConfigs) {
        let formConfig = formConfigs[key];
        result[key] = buildFormWidgetTree(formConfig.container.components);
      }
      return result;
    },

    // 表单项map
    formWidgetMap: state => {
      let result = {};
      let formConfigs = state.formConfigs;
      for (const key in formConfigs) {
        let formConfig = formConfigs[key];
        result[key] = buildFormWidgetMap(formConfig.container.components);
      }
      return result;
    },

    // 获取数据源的结构
    formListeners: state => {
      return state.widgetConfigStruct.formListeners || [];
    }
  }
};

export default form;
