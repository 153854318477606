const common = {
  state: {
    isInited: false, // 项目运行后，状态改为ture, 用于判断刷新
    // pageLoading: false,
    activedThreads: 0,
    loginType: null,
    user: null,
    menus: null,
    clientWidth: 1920,
    clientHeight: 937,
    // 当前公司的数据
    companyData: null,
  },
  mutations: {
    SET_LOAD_STATUS(state, data) {
      state.isInited = data
    },
    ADD_ACTIVED_THREAD(state, data = 1) {
      state.activedThreads += data
    },
    SUB_ACTIVED_THREAD(state, data = 1) {
      state.activedThreads -= data
    },

    SET_USER(state, data) {
      state.user = data
    },
    SET_MENUS(state, data) {
      state.menus = data
    },
    SET_LOGIN_TYPE(state, data) {
      state.loginType = data
    },

    SET_CLIENT_WIDTH(state, data) {
      state.clientWidth = data
    },
    SET_CLIENT_HEIGHT(state, data) {
      state.clientHeight = data
    },
    SET_COMPANY_DATA(state, data) {
      state.companyData = data
    },
  },
  actions: {
    setLoadStatus({
      commit
    }, data) {
      commit("SET_LOAD_STATUS", data);
    },
    setUser({
      commit
    }, data) {
      commit("SET_USER", data);
    },
    setMenus({
      commit
    }, data) {
      commit("SET_MENUS", data);
    },
    setLoginType({
      commit
    }, data) {
      commit("SET_LOGIN_TYPE", data);
    },
    setCompanyData({
      commit
    }, data) {
      commit("SET_COMPANY_DATA", data);
    },
  },
  getters: {

    /**
     * @description: 页面加载状态
     * @return:
     */
    pageLoading: state => {
      return state.activedThreads != 0
    },
    /**
     * @description: 获取登陆类型
     * @return:
     */
    loginType: state => {
      return state.loginType
    },


    /**
     * @description: 获取菜单
     * @return:
     */
    menus: state => {
      return state.menus
    },


    /**
     * @description: 获取用户
     * @param {type: 存储类型}
     * @return:
     */
    user: state => {
      return state.user
    },

    clientWidth: state => {
      return state.clientWidth
    },

    /**
     * @description: 获取当前用户公司信息
     * @return:
     */
     companyData: state => {
      return state.companyData
    },

  }
};
export default common;