export interface AutoOperationModel {
  type: string;
  properties: any;
}

export interface FormParamSetting {
  formParamName: string;
  processParamName: string;
}

export interface ParamOptionModel {
  name: string;
  text: string;
  formImport?: boolean;
  type: ParamTypeModel;
  defaultValue: any;
  global: boolean;
  innerParams?: InnerParamModel[];
}

export const ParamOptionData: ParamOptionModel = {
  name: '',
  text: '',
  type: {
    text: '',
    value: '',
    reality: 'none',
    truthType: '',
    valueItems: []
  },
  defaultValue: null,
  global: false
};

export interface ParamTypeModel {
  text: string;
  value: string;
  truthType: string;
  reality: string;
  valueItems?: ValueItemModel[];
  compares?: string[];
}

export interface InnerParamModel {
  key: string;
  text?: string;
  paramOptionName: string;
  types: string[];
}

export interface ValueItemModel {
  text: string;
  code: string;
  disabled: boolean;
  children?: ValueItemModel[];
}

export interface CodeSettingModel {
  split: string;
  parts: CodePartModel[];
}
export interface CodePartModel {
  type: ItemType | '';
}

export interface ConstantsPart extends CodePartModel {
  type: ItemType.CONSTANTS;
  value: string;
}

export interface VariablePart extends CodePartModel {
  type: ItemType.VARIABLE;
  varName: string;
}

export interface SerialNumberPart extends CodePartModel {
  type: ItemType.SERIAL_NUMBER;
  serialNumber: SerialNumber;
}

export enum ItemType {
  CONSTANTS = 'lang_constant',
  VARIABLE = 'lang_Paramètres',
  SERIAL_NUMBER = 'lang_serial_number1'
}

export interface SerialNumber {
  type: SerialType | '';
  varName?: string;
  length: Number;
}

export enum SerialType {
  DATE = 'lang_date',
  VARIABLE = 'lang_Paramètres'
}

export interface FlowConfigModel {
  formId: string;
  formParamSettings: FormParamSetting[];
  autoOperations: AutoOperationModel[];
  paramsOptions: ParamOptionModel[];
  codeSetting?: CodeSettingModel;
  titleVarName: string | null;
  starterVarName: string | null;
  starterGroupVarName: string | null;
}

export const FormParamSettingData = (): FormParamSetting => {
  return {
    formParamName: '',
    processParamName: ''
  };
};

export const CodeSettingData = (): CodeSettingModel => {
  return {
    split: '',
    parts: []
  };
};

export const FlowConfigData = (): FlowConfigModel => {
  return {
    formId: '',
    formParamSettings: [],
    autoOperations: [],
    paramsOptions: [],
    codeSetting: CodeSettingData(),
    titleVarName: null,
    starterVarName: null,
    starterGroupVarName: null
  };
};
