var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-mask" }, [
      _c("div", { staticClass: "page-loading" }, [
        _c("div", { staticClass: "rect1" }),
        _c("div", { staticClass: "rect2" }),
        _c("div", { staticClass: "rect3" }),
        _c("div", { staticClass: "rect4" }),
        _c("div", { staticClass: "rect5" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }