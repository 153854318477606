var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "con" },
    [
      _c(
        "el-input",
        {
          ref: "LangInput",
          class: _vm.i18nFlag ? "input-text-hidden" : "",
          attrs: {
            size: _vm.size,
            disabled: _vm.i18nFlag,
            value: _vm.showValue(_vm.value),
          },
          on: { input: _vm.changeInput },
        },
        [
          _c("template", { slot: "append" }, [
            _c(
              "div",
              {
                class: _vm.i18nFlag ? "button-alive" : "button-no-alive",
                staticStyle: { "padding-left": "4px", "padding-right": "4px" },
                on: { click: _vm.showLangDialogFn },
              },
              [
                _c("i", {
                  staticClass: "el-icon-setting",
                  staticStyle: { "font-size": "20px" },
                  style: {
                    color: _vm.i18nFlag ? "#5CBDFF" : "",
                  },
                }),
              ]
            ),
          ]),
        ],
        2
      ),
      _vm.i18nFlag
        ? _c(
            "span",
            {
              staticClass: "lang-link",
              class: { "small-lang-link": _vm.size == "small" },
              style: {
                width: _vm.inputWidth,
              },
              on: { click: _vm.handleConfigLang },
            },
            [_vm._v(" " + _vm._s(_vm.$t(_vm.value)) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }