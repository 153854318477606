








































import { Vue, Component, Watch, Prop, Model, Emit, Ref } from 'vue-property-decorator';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import * as LangApi from '@/api/system/LanguageApi';
import { langStorageKey, translateText } from '@/lang';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
// 翻译文本，接受文字，翻译成对应语言
@Component({
  name: 'LangInput'
})
export default class LangInput extends Vue {
  // @Prop({ type: String }) value: any;
  @Model('handleParentInput', { type: String }) value!: string;
  @Prop({ default: '' }) size: string;
  /******************************************************************
   *$emit
   ******************************************************************/
  @Emit('handleParentInput')
  changeLang(value): void {}

  @Ref() readonly LangInput;

  /******************************************************************
   *Data
   ******************************************************************/
  displayName: string = '';
  langReg: RegExp = /^lang_/;
  flg = false;
  i18nFlag: boolean = false; //多语言标识  true--多语言模式 false--普通模式
  vnodeKey: string = ''; //当前组件的key值
  languageId: string = '';
  valueWatch: boolean = true;
  inputWidth: string = '100%';

  /******************************************************************
   *watch
   ******************************************************************/
  @Watch('_code')
  onCodeChange(newValue, oldValue): void {
    if (newValue.key && newValue.key != this.vnodeKey) return;
    if (newValue.targetKey) {
      this.changeLang(newValue.targetKey);
    }
  }
  @Watch('value')
  valueChange(newValue, oldValue): void {
    if (this.valueWatch) {
      if (this.langReg.test(this.value)) {
        this.i18nFlag = true;
      }
    }
    this.valueWatch = false;
  }

  showLangDialogFn(): void {
    this.i18nFlag = !this.i18nFlag;
    if (this.i18nFlag) {
      this.handleConfigLang();
    } else {
      this.deleteConfigLang();
    }
    // this.changeLang('');
  }
  /******************************************************************
   *computed
   ******************************************************************/
  get _code() {
    return this.$store.state.home.currentCode;
  }
  created() {
    this.vnodeKey = SystemUtil.uuid();

    if (this.langReg.test(this.value)) {
      this.i18nFlag = true;
    }
  }

  mounted() {
    this.inputWidth = this.LangInput.$el!.clientWidth - 40 + 'px';
  }

  /******************************************************************
   *methods
   ******************************************************************/
  handleConfigLang() {
    const data = { sourceKey: this.value, key: this.vnodeKey };
    this.$store.dispatch('home/changeCurrentCode', data);
    this.$store.dispatch('home/changeVisible', true);
  }

  //输入时
  changeInput(val) {
    if (!this.i18nFlag) {
      this.changeLang(val);
    }
  }
  deleteConfigLang() {
    if (!this.value) return;
    if (this.value && /^lang_/.test(this.value)) {
      this.changeLang('');
      // 先查询 id
      LangApi.languageResourceDetail({ code: this.value }).then(res => {
        if (res.data) {
          let languageId = res.data.id;
          let langCode = res.data.code;
          // 调用删除
          LangApi.languageResourceDelete({ id: languageId }).then(res => {
            this.changeLang(translateText(langCode));//回显删除后的文字
            if (this.$i18n.messages[langCode]) {
              delete this.$i18n.messages[langCode];
              StorageUtil.setLocalStorage(langStorageKey, this.$i18n.messages);
            } else {
              this.$i18n.messages[langCode] = null;
              delete this.$i18n.messages[langCode];
              StorageUtil.setLocalStorage(langStorageKey, this.$i18n.messages);
            }
          });
        }
      });
    }
  }
  showValue(val) {
    if (this.i18nFlag) return this.$t(val); //多语言
    return val;
  }
  showLangValueFn(val) {}
}
