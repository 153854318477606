import { FlowConfigData, FlowConfigModel } from './FlowConfigModel';
import { FlowGraphData, FlowGraphModel } from './FlowGraphModel';

export interface CustomBpmModel {
  config: FlowConfigModel; // 流程配置
  graph: FlowGraphModel; // 流程图配置
}

export interface FlowModel {
  id?: string;
  code: string;
  name: string;
  customBpmModel: CustomBpmModel;
  status?: string;
  description?: string;
  applicationId?: string;
  createDate?: number;
  updateDate?: number;
  sort?: number;
}

export const customBpmModelData = (): CustomBpmModel => {
  return {
    config: FlowConfigData(), // 流程配置
    graph: FlowGraphData() // 流程图配置
  };
};

export const FlowData = {
  id: '',
  code: '',
  name: '',
  customBpmModel: customBpmModelData(),
  status: '',
  description: '',
  applicationId: ''
};
