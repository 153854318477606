import { translateText } from '@/lang';

const Layout = () => import('@/views/layout/index.vue');
export default [
  {
    path: '/user',
    name: 'User',
    component: Layout,
    redirect: '/user/person_transfer',
    children: [
      {
        path: 'person_transfer',
        name: 'Person_transfer',
        component: () => import('@/views/user/person-transfer/person-transfer-list'),
        meta: {
          keepAlive: true,
          title: translateText('lang_personnel_transfer_management')
        }
      },
      {
        path: 'status_change',
        name: 'Status_change',
        component: () => import('@/views/user/status-change/status-list'),
        meta: {
          keepAlive: true,
          title: translateText('lang_personnel_status_change')
        }
      }
    ],
    meta: {
      keepAlive: true,
      title: translateText('lang_personnel_management_center')
    }
  },
  {
    path: '/river',
    name: 'river',
    component: () => import('@/views/main/index'),
    meta: {
      keepAlive: true,
      title: translateText('lang_personnel_status_change')
    },
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/main/home'),
        meta: {
          keepAlive: true,
          title: translateText('lang_digital_transformation_platform')
        }
      },
      {
        path: '/task',
        name: 'task',
        component: () => import('@/views/main/task'),
        meta: {
          keepAlive: true,
          title: translateText('lang_personnel_status_change')
        }
      },
      {
        path: '/document',
        name: 'Document',
        component: () => import('@/views/document/document-list'),
        meta: {
          keepAlive: true,
          title: 'lang_document_management'
        }
      },
      {
        path: '/list',
        name: 'Application',
        component: () => import('@/views/application/app-list-style1.0'),
        meta: {
          keepAlive: true,
          title: 'lang_auto_83d9741b_2f90_4faf_a994_99dc887a2b4c'
        }
      },
      {
        path: '/form/design',
        name: 'FormDesign',
        component: () => import('@/views/main/index1.vue'),
        meta: {
          keepAlive: true,
          title: 'lang_application_form_design'
        }
      },
      {
        path: '/documentListCreate',
        component: () => import('@/views/main/documentListCreate.vue'),
        meta: {
          keepAlive: true,
          title: 'lang_release'
        }
      },
      {
        path: '/documentListRead',
        component: () => import('@/views/main/documentListRead.vue'),
        meta: {
          keepAlive: true,
          title: 'lang_see'
        }
      },
      {
        path: '/documentManagement',
        component: () => import('@/views/main/documentManagement.vue'),
        meta: {
          keepAlive: true,
          title: 'lang_app_management'
        }
      },
      {
        path: '/routine',
        component: () => import('@/views/main/routine.vue'),
        meta: {
          keepAlive: true,
          title: 'lang_my_mission'
        }
      },
      {
        path: '/waitList',
        component: () => import('@/views/front-frame/front-wait-list2.0'),
        meta: {
          keepAlive: true,
          title: 'lang_to_do_list'
        }
      },
      {
        path: '/doneList',
        component: () => import('@/views/front-frame/front-done-list2.0'),
        meta: {
          keepAlive: true,
          title: 'lang_done_items'
        }
      },
      {
        path: '/possibilityList',
        component: () => import('@/views/front-frame/front-possibility-list2.0'),
        meta: {
          keepAlive: true,
          title: 'lang_predicted_events'
        }
      },
      {
        path: '/startedList',
        component: () => import('@/views/front-frame/front-started-list2.0'),
        meta: {
          keepAlive: true,
          title: 'lang_initiating_matters'
        }
      },
      {
        path: '/create',
        component: () => import('@/views/home/flow2/flow-create'),
        meta: {
          keepAlive: true,
          title: 'lang_new_process'
        }
      },
    ]
  },
  {
    path: '/money',
    name: 'money',
    component: () => import('@/views/main/money.vue'),
    meta: {
      keepAlive: true,
      title: 'lang_application_form_design'
    }
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('@/views/document/document-detail'),
    meta: {
      keepAlive: true,
      title: 'lang_document_detail'
    }
  }
];
