import { render, staticRenderFns } from "./glb-loading.vue?vue&type=template&id=ed3871ae&scoped=true&"
import script from "./glb-loading.vue?vue&type=script&lang=js&"
export * from "./glb-loading.vue?vue&type=script&lang=js&"
import style0 from "./glb-loading.vue?vue&type=style&index=0&id=ed3871ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed3871ae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\123\\river-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ed3871ae')) {
      api.createRecord('ed3871ae', component.options)
    } else {
      api.reload('ed3871ae', component.options)
    }
    module.hot.accept("./glb-loading.vue?vue&type=template&id=ed3871ae&scoped=true&", function () {
      api.rerender('ed3871ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/glb-loading/glb-loading.vue"
export default component.exports