import { NodeConfig } from '@logicflow/core';
import { ParamOptionModel } from './FlowConfigModel';
import { translateText } from '@/lang';
// 自动操作 类型
interface AutoOperations {
  time: string;
  type: string;
}

// 自动操作 类型
interface AutoOperations {
  time: string;
  type: string;
}

// 节点动作 参数设置
interface ParamValueSettingModel {
  paramOptionName: string;
  value: string;
}
export enum ActionOperationType {
  SUBMIT = 'submit',
  REJECT = 'reject',
  DISAGREE = 'disagree',
  SAVE = 'save',
  ASSIGN = 'assign',
  CC = 'cc',
  DELEGATE = 'delegate',
  REVOKE = 'revoke',
  PRINT = 'print',
  EMPTY = ''
}
export enum ActionTypeEnum {
  PROCESS = 'process',
  FLUSH = 'form_flush',
  DIALOG = 'form_dialog',
  BACK = 'form_back',
  CLOSE = 'form_close',
  EMPTY = ''
}
// 节点动作列表类型
export interface NodeActionModel {
  actionKey: string;
  actionName: string;
  actionType: ActionTypeEnum;
  displayActionName: string;
  displayActionExplain: string;
  enabled: boolean;
  actionExplain?: string;
  operation: ActionOperationType;
  custom: boolean;
  sort: number;
  children: NodeActionModel[];
  formSetting: FormSettingModel;
  paramValueSettings: ParamValueSettingModel[];
  [prop: string]: any;
}

export const NodeActionData: NodeActionModel = {
  actionKey: '',
  actionName: '',
  displayActionName: '',
  actionType: ActionTypeEnum.EMPTY,
  actionExplain: '',
  displayActionExplain: '',
  enabled: true,
  custom: true,
  operation: ActionOperationType.EMPTY,
  sort: 0,
  children: [],
  formSetting: {
    componentsSettings: []
  },
  paramValueSettings: []
};

export const FlowSystemActions: any[] = [
  {
    code: 'submit',
    name: 'lang_submit'
  },
  {
    code: 'disagree',
    name: 'lang_go_back'
  },
  {
    code: 'save',
    name: 'lang_save_'
  },
  {
    code: 'assign',
    name: 'lang_countersign'
  },
  {
    code: 'cc',
    name: 'lang_cc'
  },
  {
    code: 'delegate',
    name: 'lang_transfer'
  },
  {
    code: 'revoke',
    name: 'lang_withdraw'
  },
  {
    code: 'print',
    name: 'lang_print'
  },
  { code: 'submitAny', name: 'lang_submit_to_any_node' }, //提交到任意节点
  { code: 'delete', name: 'lang_delete_originating_process' } //删除发起流程
];

// 会签设置
export interface MultipleSettingModel {
  multiple: boolean;
  sequential: boolean;
  allowChangeAssignee: boolean;
  changeAssigneeName: string;
}

export const MultipleSettingData: MultipleSettingModel = {
  multiple: false,
  sequential: false,
  allowChangeAssignee: false,
  changeAssigneeName: translateText('lang_countersign')
};

export interface InstPropsSettingModel {
  submitWordsVarName: string | null;
  submitWordsFilesVarName: string | null;
}

export const InstPropsSettingData: InstPropsSettingModel = {
  submitWordsVarName: 'var_context_submit_words',
  submitWordsFilesVarName: 'var_context_submit_words_files'
};

export interface NodeUserConfigModel {
  id: number;
  name: string;
  key: string;
  disabled?: boolean;
  children: NodeUserConfigModel[];
}

export const NodeUserConfigData: NodeUserConfigModel[] = [
  // 人员设置 右边表格已选择人员显示列表数据
  {
    id: 100067,
    name: translateText('lang_approver'),
    key: 'approval',
    disabled: true,
    children: [
      {
        id: 10006711,
        name: translateText('lang_personnel'),
        key: 'userIds',
        children: []
      },
      {
        id: 10006712,
        name: translateText('lang_organization'),
        key: 'groupIds',
        children: []
      },
      {
        id: 10006713,
        name: translateText('lang_role'),
        key: 'roleIds',
        children: []
      },
      {
        id: 10006714,
        name: translateText('lang_variable'),
        key: 'variables',
        children: []
      }
    ]
  },
  {
    id: 100068,
    name: translateText('lang_executor'),
    key: 'execution',
    disabled: true,
    children: [
      {
        id: 10006811,
        name: translateText('lang_personnel'),
        key: 'userIds',
        children: []
      },
      {
        id: 10006812,
        name: translateText('lang_organization'),
        key: 'groupIds',
        children: []
      },
      {
        id: 10006813,
        name: translateText('lang_role'),
        key: 'roleIds',
        children: []
      },
      {
        id: 10006814,
        name: translateText('lang_variable'),
        key: 'variables',
        children: []
      }
    ]
  },
  {
    id: 100069,
    name: translateText('lang_CC_person'),
    key: 'notify',
    disabled: true,
    children: [
      {
        id: 10006911,
        name: translateText('lang_personnel'),
        key: 'userIds',
        children: []
      },
      {
        id: 10006912,
        name: translateText('lang_organization'),
        key: 'groupIds',
        children: []
      },
      {
        id: 10006913,
        name: translateText('lang_role'),
        key: 'roleIds',
        children: []
      },
      {
        id: 10006914,
        name: translateText('lang_variable'),
        key: 'variables',
        children: []
      }
    ]
  }
];

export interface AssigneeStaticSelectorModel {
  userIds: string[];
  groupIds: string[];
  roleIds: string[];
}

export interface AssigneeSelectorModel extends AssigneeStaticSelectorModel {
  variables: string[];
}
export const AssigneeStaticSelectorData: AssigneeStaticSelectorModel = {
  userIds: [],
  groupIds: [],
  roleIds: []
};

export const AssigneeSelectorData: AssigneeSelectorModel = {
  ...AssigneeStaticSelectorData,
  variables: []
};

// 人员设置 类型
export interface NodeAssigneeModel {
  approval: AssigneeSelectorModel;
  execution: AssigneeSelectorModel;
  notify: AssigneeSelectorModel;
}

export const NodeAssigneeData: NodeAssigneeModel = {
  approval: AssigneeSelectorData,
  execution: AssigneeSelectorData,
  notify: AssigneeSelectorData
};

// 退回设置
export interface ReturnSettingModel {
  allowReturn: boolean;
  freeReturn?: boolean;
  returnNodeId?: string;
  resetAssignee?: boolean;
  resetAssigneeScope?: AssigneeStaticSelectorModel;
}

export const ReturnSettingData: ReturnSettingModel = {
  allowReturn: false,
  freeReturn: false,
  returnNodeId: '',
  resetAssignee: false,
  resetAssigneeScope: AssigneeStaticSelectorData
};

export interface FormSettingModel {
  componentsSettings: FormWidgetSettingModel[];
}

export const FormSettingData: FormSettingModel = {
  componentsSettings: []
};

export interface FormWidgetSettingModel {
  label?: string;
  componentsCode: string;
  parentId: string;
  readonly: boolean;
  hidden: boolean;
  required: boolean;
  locationIndex: number;
  dialog?: boolean;
  children?: FormWidgetSettingModel[];
}

// 表单映射设置
export interface FormMappingModel {
  // 表单ID
  formId: string;
  // 表单数据标题的参数
  titleVarName: string;
  // 映射项
  formMappings?: WidgetMappingModel[];
}

const FormMappingData: FormMappingModel = {
  formId: '',
  titleVarName: '',
  formMappings: []
};

// 表单映射
export interface WidgetMappingModel {
  // 表单项Code
  formModelCode?: string | null;
  // 参数名称
  varName?: string;
}

export interface NoticeSetting {
  arrivedNotice: boolean;
  timeOutNotice: boolean;
  timeOutHours: number;
  intervalInMinutes: number;
  repeatCount: number;
  dueDateNotice: boolean;
  dueDatePreHours: number;
  dueDateIntervalInMinutes: number;
  dueDateRepeatCount: number;
}
export const NoticeSettingData = (): NoticeSetting => {
  return {
    arrivedNotice: true,
    timeOutNotice: false,
    timeOutHours: 6,
    intervalInMinutes: 60,
    repeatCount: 1,
    dueDateNotice: true,
    dueDatePreHours: 72,
    dueDateIntervalInMinutes: 1440,
    dueDateRepeatCount: 1
  };
};
// 子流程参数数据 类型
export interface SubBpmSettingModel {
  // 是否启动触发子流程
  openTriggerSubBpm: boolean;
  // 子流程的ID
  subBpmProcessId: string;
  // 触发子流程的发起者参数
  collectionVarName: string;
  // 标题的参数
  titleVarName: string;
  // 从子流程返回的意见参数
  submitWordsReturnVarName?: string;
  // 子流程返回的参数
  returnParamNames?: ParamOptionModel[];
  // 表单映射的设置
  formMappingSetting: FormMappingModel;
}
export const SubBpmSettingData: SubBpmSettingModel = {
  // 是否启动触发子流程
  openTriggerSubBpm: true,
  // 子流程的ID
  subBpmProcessId: '',
  // 触发子流程的发起者参数
  collectionVarName: '',
  // 标题的参数
  titleVarName: '',
  // 从子流程返回的意见参数
  submitWordsReturnVarName: '',
  // 子流程返回的参数
  returnParamNames: [],
  // 表单映射的设置
  formMappingSetting: FormMappingData
};

// 流程节点配置
export interface NodePropertiesModel extends Record<string, any> {
  autoOperations: AutoOperations[];
  multipleSetting: MultipleSettingModel;
  instPropsSetting: InstPropsSettingModel;
  nodeActions: NodeActionModel[];
  nodeAssignee: NodeAssigneeModel;
  extensions: Record<string, any>;
  submitWordsNodes: string[];
  returnSetting: ReturnSettingModel;
  formSetting: FormSettingModel;
  subBpmSetting?: SubBpmSettingModel;
  openAutoSubmit: boolean;
  noticeSetting: NoticeSetting;
}

export const NodePropertiesData: NodePropertiesModel = {
  autoOperations: [],
  multipleSetting: MultipleSettingData,
  instPropsSetting: InstPropsSettingData,
  nodeActions: [],
  nodeAssignee: NodeAssigneeData,
  extensions: {},
  submitWordsNodes: [],
  returnSetting: ReturnSettingData,
  formSetting: FormSettingData,
  subBpmSetting: SubBpmSettingData,
  openAutoSubmit: false,
  noticeSetting: NoticeSettingData()
};

export const TaskNodeDefaultData = {
  type: 'wait_task',
  text: {
    value: '未命名节点'
  },
  properties: {
    extensions: {},
    formSetting: {},
    nodeActions: [
      {
        actionKey: 'ea774034_8e79_4ee6_a821_e1b0f4f7a3fd',
        operation: 'submit',
        actionType: 'process',
        actionName: '提交',
        actionExplain: '',
        enabled: true,
        formSetting: {
          componentsSettings: []
        },
        sort: 1,
        custom: true,
        taskPages: ['task_runtime'],
        paramValueSettings: [],
        children: []
      }
    ],
    multipleSetting: {
      multiple: false,
      sequential: false,
      allowChangeAssignee: false,
      changeAssigneeName: '加签'
    },
    autoOperations: [],
    returnSetting: {
      allowReturn: false,
      freeReturn: false,
      returnNodeId: '',
      resetAssignee: false,
      resetAssigneeScope: {
        userIds: [],
        groupIds: [],
        roleIds: []
      }
    },
    nodeAssignee: {
      approval: {
        userIds: [],
        groupIds: [],
        roleIds: [],
        variables: []
      },
      execution: {
        userIds: [],
        groupIds: [],
        roleIds: [],
        variables: []
      },
      notify: {
        userIds: [],
        groupIds: [],
        roleIds: [],
        variables: []
      }
    },
    submitWordsNodes: [],
    subBpmSetting: {
      openTriggerSubBpm: true,
      subBpmProcessId: '',
      collectionVarName: '',
      titleVarName: '',
      submitWordsReturnVarName: '',
      returnParamNames: [],
      formMappingSetting: {
        formId: '',
        titleVarName: '',
        formMappings: []
      }
    },
    instPropsSetting: {},
    openAutoSubmit: false,
    noticeSetting: NoticeSettingData()
  }
};

export interface GraphNodeModel extends NodeConfig {}

export const GraphNodeData: GraphNodeModel = {
  id: '',
  type: '',
  x: 0,
  y: 0,
  properties: NodePropertiesData
};

export const FlowActionTypes: any[] = [
  { code: 'process', name: 'lang_process_events' },
  { code: 'form_flush', name: 'lang_switch_page' },
  { code: 'form_dialog', name: 'lang_pop_up_page' },
  { code: 'form_back', name: 'lang_return_to_superior' },
  { code: 'form_close', name: 'lang_close_pop_up_window' }
];

export const FlowActionUses: any[] = [
  { code: 'process_new', name: 'lang_new_process' },
  { code: 'task_startup', name: 'lang_my_initiation' },
  { code: 'task_runtime', name: 'lang_my_to_do_list' },
  { code: 'task_completed', name: 'lang_my_work_has_been_done' },
  { code: 'task_end', name: 'lang_matters_to_be_settled' },
  { code: 'task_cc', name: 'lang_my_CC' },
  { code: 'task_delegete', name: 'lang_my_referral' }
];
