const Layout = () => import('@/views/layout/index.vue');
export default [
  {
    path: '/archive',
    name: 'Archive',
    component: Layout,
    children: [
      {
        path: 'personal',
        name: 'ArchivePersonal',
        component: () => import('@/views/archive/archive-personal'),
        meta: {
          keepAlive: true,
          title: 'lang_archive_personal'
        }
      },
      {
        path: 'flow',
        name: 'ArchiveFlow',
        component: () => import('@/views/archive/archive-flow'),
        meta: {
          keepAlive: true,
          title: 'lang_archive_personal'
        }
      }
    ],
    meta: {
      keepAlive: true,
      title: 'lang_archive_mng'
    }
  }
];
