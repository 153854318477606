





















import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import * as LangApi from '@/api/system/LanguageApi';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import { langStorageKey } from '@/lang';
@Component({
  name: 'LangDialog'
})
export default class LangDialog extends Vue {
  id: string = '';
  list: LangApi.LanguageContentType[] = [];
  langs: Record<string, any>[] = [];
  langReg: RegExp = /^lang_/;
  get code() {
    return this.$store.state.home.currentCode;
  }
  get sourceKey() {
    return this.$store.state.home.currentCode.sourceKey;
  }
  get visible() {
    return this.$store.state.home.langVisible;
  }
  parseType(value) {
    let target = this.langs.find(a => a.id === value);
    return target?.name;
  }
  addLanguage() {
    this.list = this.langs
      .filter(a => a.content)
      .map(a => {
        return {
          languageType: a.id,
          content: a.content
        };
      });
    let isUpdatge = this.langReg.test(this.sourceKey);
    if (!isUpdatge) {
      let targetKey = 'lang_auto_' + SystemUtil.uuid();
      LangApi.languageResourceAdd({ code: targetKey, contents: this.list, staticFlag: false }).then((res: any) => {
        if (res.code == '1') {
          this.injectI18n(targetKey);
          this.$store.dispatch('home/changeCurrentCode', { sourceKey: this.sourceKey, targetKey: targetKey, key: this.code.key });
          this.$store.dispatch('home/changeVisible', false);
        }
      });
    } else {
      let targetKey = this.sourceKey;
      LangApi.languageResourceUpdate({ id: this.id, code: targetKey, contents: this.list, staticFlag: false }).then((res: any) => {
        if (res.code == '1') {
          this.injectI18n(targetKey);
          this.$store.dispatch('home/changeCurrentCode', { sourceKey: this.sourceKey, targetKey: targetKey, key: this.code.key });
          this.$store.dispatch('home/changeVisible', false);
        }
      });
    }
  }
  //   插入到本地语言存储
  injectI18n(langKey) {
    this.list.forEach(item => {
      let langCode = this.langs.find(a => a.id === item.languageType)!.code;
      let mergeData = { [langKey]: item.content };
      this.$i18n.mergeLocaleMessage(langCode, mergeData);
    });
    StorageUtil.setLocalStorage(langStorageKey, this.$i18n.messages);
  }
  closeDialog() {
    if (!this.sourceKey) {
    }
    this.$store.dispatch('home/changeVisible', false);
  }
  mounted() {
    LangApi.getLanguageTypeQueryList({}).then(res => {
      this.langs = res.data.map(a => {
        a.content = '';
        return a;
      });
    });
  }
  @Watch('visible')
  onVisChange(v) {
    if (v) {
      this.$nextTick(() => {
        if (this.sourceKey && /^lang_/.test(this.sourceKey)) {
          LangApi.languageResourceDetail({ code: this.sourceKey }).then(res => {
            if (res.data) {
              res.data.contents.forEach(a => {
                let target = this.langs.find(type => type.id === a.languageType);
                if (target) {
                  this.$set(target, 'content', a.content);
                }
              });
              this.id = res.data.id;
            }
          });
        }
      });
    } else {
      this.list = [];
      this.id = '';
      this.langs.forEach(a => {
        a.content = '';
      });
    }
  }
}
