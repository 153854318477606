import HttpUtil from '@/utils/HttpUtil';
export interface AddResourceType {
  code: string;
  staticFlag: boolean;
  contents: LanguageContentType[];
}
export interface LanguageContentType {
  languageType: string;
  content: string;
}
/**
 * 添加语言资源
 * @param {AddResourceType} params
 * @returns
 */
export const languageResourceAdd = (params: AddResourceType) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'language/resource/add';
  return HttpUtil.post(url, params);
};

// 修改语言资源
export const languageResourceUpdate = (params: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'language/resource/update';
  return HttpUtil.post(url, params);
};

// 删除语言资源
export const languageResourceDelete = (params: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'language/resource/delete?id=' + params.id;
  return HttpUtil.post(url, params);
};

/**
 * 查询语言资源列表
 * @param {*} params
 * @param {string} params.code 唯一码
 * @param {string} params.description 应用描述
 * @returns
 */
export const languageResourceQueryList = (params: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'language/resource/query/list';
  return HttpUtil.post(url, params);
};

/**
 * 查询语言资源列表-分页
 * @param {*} params
 * @param {string} params.code 唯一码
 * @param {string} params.description 应用描述
 * @returns
 */
export const languageResourceQueryPage = (params: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/language/resource/query/page';
  return HttpUtil.post(url, params);
};

// 获取语言类型列表
export const getLanguageTypeQueryList = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'language/type/query/list';
  return HttpUtil.post(url, params);
};

// 添加语言类型
export const languageTypeAdd = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'language/type/add';
  return HttpUtil.post(url, params);
};

// 修改语言类型
export const languageTypeUpdate = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'language/type/update';
  return HttpUtil.post(url, params);
};

// 删除语言类型
export const languageTypeDelete = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'language/type/delete';
  return HttpUtil.get(url, params);
};
/**
 * 根据code查询语言资源
 * @param params
 * @returns
 */
export const languageResourceDetail = (params: { code: string }) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'language/resource/query/by/code';
  return HttpUtil.get(url, params);
};

// 获取语言数据
export const getLanguageResourceQueryI18n = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'language/resource/query/i18n';
  return HttpUtil.post(url, params);
};

// 刷新缓存
export const languageResourceRefresh = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'language/resource/refresh';
  return HttpUtil.get(url, params);
};

// 刷新静态数据
export const valueItemsrefresh = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/config/data/valueItemsrefresh';
  return HttpUtil.post(url, params);
};

// 刷新多语言
export const deletei18n = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/language/resource/query/deletei18n';
  return HttpUtil.post(url, params);
};

// 获取md5值
export const languageResourceQueryI18nChecksum = async (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'language/resource/query/i18n/checksum';
  return await HttpUtil.post(url, params);
};
