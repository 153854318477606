import { WidgetModel } from '@/models/form/WidgetModel';
import { FormData } from '@/models/form/FormModel';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import StoreIndex from '@/store/StoreIndex';
import { getTranslatedCompName } from '@/utils/translateText';

import { translateText } from '@/lang/index';
/**
 * 从vuex获取表单配置
 */
export function getFormConfig(formId) {
  if (formId) {
    return StoreIndex.getters.formConfigs[formId];
  } else {
    return SystemUtil.cloneDeep(FormData);
  }
}

/**
 * 从vuex获取表单参数
 */
export function getFormParams(formId) {
  let result: any[] = [];
  let formConfig = getFormConfig(formId);
  if (formConfig && formConfig.container.paramsOptions) {
    formConfig.container.paramsOptions.forEach(item => {
      let temp: any = {
        code: item.name,
        text: item.text,
        disabled: false,
        valueType: 'FORM_ARGUMENT',
        reality: item.type.reality,
        value: item.type.value
      };
      result.push(temp);
    });
  }
  return result;
}

export function isRow(obj: Record<string, any>) {
  return obj.compType === 'row';
}
export function isTable(obj: Record<string, any>) {
  return obj.compType === 'table';
}
export function isCard(obj: Record<string, any>) {
  return obj.compType === 'card';
}
export function isTab(obj: Record<string, any>) {
  return obj.compType === 'tab';
}

// 构建表单控件树
export function buildFormWidgetTree(widgets) {
  let widgetTree: any = [];
  if (widgets && widgets.length) {
    widgets.forEach((widgetItem, widgetItemIndex) => {
      let treeItem: any = {
        id: widgetItem.id,
        label: getTranslatedCompName(widgetItem),
        compCode: widgetItem.compCode,
        compType: widgetItem.compType,
        dataType: widgetItem.dataType,
        groupType: widgetItem.groupType,
        display: widgetItem.display,
        reality: getReality(widgetItem)
      };
      buildWidgetChildren(widgetItem, treeItem);
      widgetTree.push(treeItem);
    });
  }
  return widgetTree;
}
function buildWidgetChildren(widget, treeItem) {
  if ('card' == widget.compType) {
    treeItem.children = [];
    if (widget.properties.components) {
      widget.properties.components.forEach((cardComponent, cardComponentIndex) => {
        let cardItem: any = {
          id: cardComponent.id,
          compCode: cardComponent.compCode,
          label: getTranslatedCompName(cardComponent),
          compType: cardComponent.compType,
          dataType: cardComponent.dataType,
          groupType: cardComponent.groupType,
          display: cardComponent.display,
          reality: getReality(cardComponent)
        };
        buildWidgetChildren(cardComponent, cardItem);
        treeItem.children.push(cardItem);
      });
    }
  } else if ('row' == widget.compType) {
    treeItem.children = [];
    widget.properties.gridColumns.forEach(gridColumn => {
      if (gridColumn.components) {
        gridColumn.components.forEach((gridCellWidget, gridCellWidgetIndex) => {
          let gridItem = {
            id: gridCellWidget.id,
            compCode: gridCellWidget.compCode,
            label: getTranslatedCompName(gridCellWidget),
            compType: gridCellWidget.compType,
            dataType: gridCellWidget.dataType,
            groupType: gridCellWidget.groupType,
            display: gridCellWidget.display,
            reality: getReality(gridCellWidget)
          };
          treeItem.children.push(gridItem);
        });
      }
    });
  } else if (['table', 'tab'].includes(widget.compType)) {
    treeItem.children = [];
    if (widget.properties.components) {
      widget.properties.components.forEach((tableCellWidget, tableCellWidgetIndex) => {
        let tableItem = {
          id: tableCellWidget.id,
          compCode: tableCellWidget.compCode,
          label: getTranslatedCompName(tableCellWidget),
          compType: tableCellWidget.compType,
          dataType: tableCellWidget.dataType,
          groupType: tableCellWidget.groupType,
          display: tableCellWidget.display,
          reality: getReality(tableCellWidget)
        };
        treeItem.children.push(tableItem);
      });
    }
  } else if (widget.dataSource && widget.dataSource.dataList) {
    treeItem.dataSource = widget.dataSource.dataList;
  }
}

// 根据compCode获取 组件信息
export function getComponentInfo(formWidgets, compCode) {
  let result: any = {};
  result = getComponentInfoNormalFn(formWidgets, compCode, result);
  return result;
}
function getComponentInfoNormalFn(formWidgets, compCode, result) {
  for (let i = 0; i < formWidgets.length; i++) {
    let item = formWidgets[i];
    if (item.compCode == compCode) {
      return item;
    } else {
      if (item.compType == 'row' && item.properties.gridColumns.length) {
        result = getComponentInfoRowFn(item.properties.gridColumns, compCode, result);
      } else if (item.properties.components && item.properties.components.length) {
        result = getComponentInfoNormalFn(item.properties.components, compCode, result);
      }
    }
  }
  return result;
}
function getComponentInfoRowFn(formWidgets, compCode, result) {
  for (let i = 0; i < formWidgets.length; i++) {
    let item = formWidgets[i];
    if (item.components[0] && item.components[0].compCode == compCode) {
      return item.components[0];
    } else if (item.components[0] && item.components[0].properties.components?.length) {
      result = getComponentInfoNormalFn(item.components[0].properties.components, compCode, result);
    }
  }
  return result;
}

/**
 * 从vuex获取表单项树;
 */
export function getFormWidgetTree(formId) {
  if (formId) {
    return StoreIndex.getters.formWidgetTree[formId];
  }
  return [];
}

export function getReality(widgetItem): string {
  let reality = 'none';
  if (widgetItem.dataSource && widgetItem.dataSource.dataSourceConfig) {
    reality = widgetItem.dataSource.dataSourceConfig.reality;
  }
  return reality;
}

// 构建表单项map
export function buildFormWidgetMap(formWidgets: WidgetModel[]) {
  let formWidgetsMap = {};
  formWidgets!.forEach(item => {
    formWidgetsMap[item.compCode as string] = item;
    if (item.compType == 'card') {
      item!.properties.components!.forEach(el => {
        formWidgetsMap[el.compCode as string] = el;
        if (el.compType == 'row') {
          el!.properties.gridColumns!.forEach(elel => {
            elel.components!.forEach(child => {
              formWidgetsMap[child.compCode as string] = child;
            });
          });
        } else if (['table', 'tab'].includes(el.compType)) {
          el.properties.components!.forEach(elel => {
            formWidgetsMap[elel.compCode as string] = elel;
          });
        }
      });
    } else if (item.compType == 'row') {
      item.properties.gridColumns!.forEach(el => {
        el.components!.forEach(child => {
          formWidgetsMap[child.compCode as string] = child;
        });
      });
    } else if (['table', 'tab'].includes(item.compType)) {
      item.properties.components!.forEach(el => {
        formWidgetsMap[el.compCode as string] = el;
      });
    }
  });
  return formWidgetsMap;
}

/**
 * 从vuex里获取表单项map集合
 */
export function getFormWidgetMap(formId) {
  if (formId) {
    return StoreIndex.getters.formWidgetMap[formId];
  }
  return [];
}

/**
 * 从vuex里获取表单默认数据源配置
 */
export function getFormDataSourceMap(formId) {
  if (formId) {
    return StoreIndex.getters.dataSourceMaps[formId];
  }
  return [];
}
// 获取该表单项配置的数据源配置
export function getCurrentDataSourceConfig(formWidget: WidgetModel) {
  if (formWidget.dataSource && formWidget.dataSource.dataSourceConfig) {
    return formWidget.dataSource.dataSourceConfig;
  }
  return null;
}

/**
 * 构建表单变量树
 */
export function buildVariableTree(variableData: any) {
  let variableTree: any = {
    valueType: 'FORM_ARGUMENT',
    data: []
  };
  if (variableData && variableData.length) {
    let variableTreeRoot: any = {
      code: 'FORM_ARGUMENT',
      text: translateText('lang_form_parameters'),
      disabled: true,
      valueType: 'FORM_ARGUMENT',
      children: []
    };
    variableData.forEach(item => {
      let temp: any = {
        code: item.name,
        text: translateText(item.text),
        displayText: item.displayText,
        disabled: false,
        reality: item.reality,
        type: item.type,
        valueType: 'FORM_ARGUMENT'
      };
      variableTreeRoot.children.push(temp);
    });
    variableTree.data = [variableTreeRoot];
  }
  return variableTree;
}

export function buildWidgetTreeForDataSource(widgetList) {
  let widgetTree: any[] = [];
  if (widgetList && widgetList.length) {
    let rootNode: any = {
      id: 'FORM_DATA',
      code: 'FORM_DATA',
      text: translateText('lang_form_component'),
      displayText: translateText('lang_form_component'),
      disabled: true,
      valueType: 'FORM_DATA',
      children: []
    };
    widgetList.forEach((widgetItem, widgetItemIndex) => {
      let childNode: any = {
        id: widgetItem.id,
        code: widgetItem.compCode,
        text: getTranslatedCompName(widgetItem),
        displayText: getTranslatedCompName(widgetItem),
        disabled: false,
        valueType: 'FORM_DATA'
      };
      buildWidgetChildrenForDataSource(widgetItem, childNode);
      rootNode.children.push(childNode);
    });
    widgetTree = [rootNode];
  }
  return { valueType: 'FORM_DATA', data: widgetTree };
}

function buildWidgetChildrenForDataSource(widget, treeItem) {
  if ('card' == widget.compType) {
    treeItem.children = [];
    treeItem.disabled = true;
    if (widget.properties.components) {
      widget.properties.components.forEach((cardComponent, cardComponentIndex) => {
        let cardItem = {
          id: cardComponent.id,
          code: cardComponent.compCode,
          text: getTranslatedCompName(cardComponent),
          displayText: getTranslatedCompName(cardComponent),
          disabled: false,
          valueType: 'FORM_DATA'
        };
        buildWidgetChildrenForDataSource(cardComponent, cardItem);
        treeItem.children.push(cardItem);
      });
    }
  } else if ('row' == widget.compType) {
    treeItem.children = [];
    treeItem.disabled = true;
    widget.properties.gridColumns.forEach(gridColumn => {
      if (gridColumn.components) {
        gridColumn.components.forEach((gridCellWidget, gridCellWidgetIndex) => {
          let gridItem = {
            id: gridCellWidget.id,
            code: gridCellWidget.compCode,
            text: getTranslatedCompName(gridCellWidget),
            displayText: getTranslatedCompName(gridCellWidget),
            disabled: false,
            valueType: 'FORM_DATA'
          };
          treeItem.children.push(gridItem);
        });
      }
    });
  } else if (['table', 'tab'].includes(widget.compType)) {
    treeItem.children = [];
    treeItem.disabled = true;
    if (widget.properties.components) {
      widget.properties.components.forEach((tableCellWidget, tableCellWidgetIndex) => {
        let tableItem = {
          id: tableCellWidget.id,
          code: tableCellWidget.compCode,
          text: getTranslatedCompName(tableCellWidget),
          displayText: getTranslatedCompName(tableCellWidget),
          disabled: false,
          valueType: 'FORM_DATA'
        };
        treeItem.children.push(tableItem);
      });
    }
  }
}

/**
 * 获取逻辑比较符
 * @param {number} param
 * @returns {number}
 */
export function getCompares(type: string) {
  let result: any[] = [];
  let comparesMaps: any[] = StoreIndex.getters.compares || [];
  comparesMaps.forEach(item => {
    if (item.dataType == type) {
      result = item.compareVoList;
    }
  });
  return result;
}

/**
 * 移除无用的属性
 */
export function removeAuxiliaryProps(widgets) {
  let widgetsClone = [];
  if (widgets && widgets.length) {
    widgetsClone = widgets.map((widgetItem, widgetItemIndex) => {
      return removeAuxiliaryPropsInChildren(widgetItem);
    });
  }
  return widgetsClone;
}
function removeAuxiliaryPropsInChildren(widget) {
  if ('card' == widget.compType) {
    if (widget.properties.components) {
      widget.properties.components = widget.properties.components.map((cardComponent, cardComponentIndex) => {
        return removeAuxiliaryPropsInChildren(cardComponent);
      });
    }
  } else if ('row' == widget.compType) {
    widget.properties.gridColumns.forEach(gridColumn => {
      if (gridColumn.components) {
        gridColumn.components = gridColumn.components.forEach((gridCellWidget, gridCellWidgetIndex) => {
          if (gridCellWidget.dataSource.pageInfo) {
            delete gridCellWidget.dataSource.pageInfo;
          }
          return gridCellWidget;
        });
      }
    });
  } else if (['table', 'tab'].includes(widget.compType)) {
    if (widget.properties.components) {
      widget.properties.components = widget.properties.components.forEach((tableCellWidget, tableCellWidgetIndex) => {
        if (tableCellWidget.dataSource.pageInfo) {
          delete tableCellWidget.dataSource.pageInfo;
        }
        return tableCellWidget;
      });
    }
  } else {
    if (widget.dataSource.pageInfo) {
      delete widget.dataSource.pageInfo;
    }
    return widget;
  }
}

// 构建表单控件树
export function buildFormColumn(widgets, columnMap) {
  if (widgets && widgets.length) {
    widgets.forEach((widgetItem, widgetItemIndex) => {
      buildFormColumnChildren(widgetItem, columnMap);
    });
  }
}
function buildFormColumnChildren(widget, columnMap) {
  if ('card' == widget.compType) {
    if (widget.properties.components) {
      widget.properties.components.forEach((cardComponent, cardComponentIndex) => {
        if (columnMap[cardComponent.compCode]) {
          cardComponent.display = true;
        } else {
          cardComponent.display = false;
        }
        buildFormColumnChildren(cardComponent, columnMap);
      });
    }
  } else if ('row' == widget.compType) {
    widget.properties.gridColumns.forEach(gridColumn => {
      if (gridColumn.components) {
        gridColumn.components.forEach((gridCellWidget, gridCellWidgetIndex) => {
          if (columnMap[gridCellWidget.compCode]) {
            gridCellWidget.display = true;
          } else {
            gridCellWidget.display = false;
          }
        });
      }
    });
  } else if (['table', 'tab'].includes(widget.compType)) {
    if (widget.properties.components) {
      widget.properties.components.forEach((tableCellWidget, tableCellWidgetIndex) => {
        if (columnMap[tableCellWidget.compCode]) {
          tableCellWidget.display = true;
        } else {
          tableCellWidget.display = false;
        }
      });
    }
  } else {
    if (columnMap[widget.compCode]) {
      widget.display = true;
    } else {
      widget.display = false;
    }
  }
}

export function replaceWidget(widget: WidgetModel, widgetTree: WidgetModel[]) {
  for (let index = 0; index < widgetTree.length; index++) {
    let item = widgetTree[index];
    if (item.id == widget.id) {
      widgetTree[index] = widget;
    } else if (item.compType == 'card') {
      let cardComps = (item!.properties.components as any[]) || [];
      for (let cardCompIndex = 0; cardCompIndex < cardComps.length; cardCompIndex++) {
        let cardColItem = cardComps[cardCompIndex];
        if (cardColItem.id == widget.id) {
          cardComps[cardCompIndex] = widget;
        } else if (cardColItem.compType == 'row') {
          let cardCols = cardColItem!.properties.gridColumns;
          for (let gridColIndex = 0; gridColIndex < cardCols.length; gridColIndex++) {
            let gridCell = cardCols[gridColIndex];
            let gridCellWidgets = gridCell.components;
            for (let cellWidgetIndex = 0; cellWidgetIndex < gridCellWidgets.length; cellWidgetIndex++) {
              let cellWidget = gridCellWidgets[cellWidgetIndex];
              if (cellWidget.id == widget.id) {
                gridCellWidgets[cellWidgetIndex] = widget;
              }
            }
          }
        } else if (['table', 'tab'].includes(cardColItem.compType)) {
          let tableWidgets = cardColItem.properties.components as any[];
          for (let tableWidgetIndex = 0; tableWidgetIndex < tableWidgets.length; tableWidgetIndex++) {
            let tableWidget = tableWidgets[tableWidgetIndex];
            if (tableWidget.id == widget.id) {
              tableWidgets[tableWidgetIndex] = widget;
            }
          }
        }
      }
    } else if (item.compType == 'row') {
      let cardCols = item!.properties.gridColumns as any[];
      for (let gridColIndex = 0; gridColIndex < cardCols.length; gridColIndex++) {
        let gridCell = cardCols[gridColIndex];
        let gridCellWidgets = gridCell.components;
        for (let cellWidgetIndex = 0; cellWidgetIndex < gridCellWidgets.length; cellWidgetIndex++) {
          let cellWidget = gridCellWidgets[cellWidgetIndex];
          if (cellWidget.id == widget.id) {
            gridCellWidgets[cellWidgetIndex] = widget;
          }
        }
      }
    } else if (['table', 'tab'].includes(item.compType)) {
      let tableWidgets = item.properties.components as any[];
      for (let tableWidgetIndex = 0; tableWidgetIndex < tableWidgets.length; tableWidgetIndex++) {
        let tableWidget = tableWidgets[tableWidgetIndex];
        if (tableWidget.id == widget.id) {
          tableWidgets[tableWidgetIndex] = widget;
        }
      }
    }
  }
  return widgetTree;
}

/**
 * 获取表单中所有指定类型的组件
 */
export function getFormWidgetsByCompType(components, compType) {
  let result: any[] = [];
  components.forEach(compItem => {
    if (compItem.compType == compType) {
      result.push(compItem);
    }
    if (compItem.compType == 'card') {
      result = result.concat(getFormWidgetsByCompType(compItem.properties.components, compType));
    } else if (compItem.compType == 'row') {
      compItem.properties.gridColumns.forEach(gridCol => {
        result = result.concat(getFormWidgetsByCompType(gridCol.components, compType));
      });
    } else if (['table', 'tab'].includes(compItem.compType)) {
      result = result.concat(getFormWidgetsByCompType(compItem.properties.components, compType));
    }
  });
  return result;
}

/**
 * description  转换提交的参数数据
 * @param {number} param
 * @returns {number}
 */
export function buildFormDataForSave(formVal, formDataId) {
  let result: any[] = [];
  for (let key in formVal) {
    let val = formVal[key];
    if (Array.isArray(val)) {
      // 如果是数组，则是表格数据，转换为后台要求的格式
      val.forEach((rowData, elIndex) => {
        let arr = Object.values(rowData);
        arr.map((cl: any) => {
          cl.rowIndex = elIndex;
          if (formDataId) {
            cl.formDataId = formDataId;
          }
        });
        result.push(...arr);
      });
    } else {
      if (formDataId) {
        val.formDataId = formDataId;
      }
      result.push(val);
    }
  }
  return result;
}
