import i18n from '@/lang';
import { WidgetModel } from '@/models/form/WidgetModel';
// 翻译文本
export function translateText(text: string): string {
  return i18n.t(text) as any;
}
export function getTranslatedCompName(widget: WidgetModel) {
  const langReg = /^lang_/;
  return langReg.test(widget.compName) ? widget.displayName || i18n.t(widget.compName) : widget.compName;
}
