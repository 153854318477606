import Vue from 'vue';
import Router from 'vue-router';
import CommonRoutes from './CommonRoutes';
import SystemRoutes from './SystemRoutes';
import OrganizationRoutes from './OrganizationRoutes';
import FlowRoutes from './FlowRoutes';
import DataMngRoutes from './DataMngRoutes';
import LogRoutes from './LogRoutes';
import ArchiveRoutes from './ArchiveRoutes';
import EntrustRoutes from './EntrustRoutes';
import UserRoutes from './UserRoutes';
import RouterInterceptor from '@/interceptor/RouterInterceptor';

const routes = [].concat(
  CommonRoutes,
  SystemRoutes,
  OrganizationRoutes,
  FlowRoutes,
  DataMngRoutes,
  LogRoutes,
  ArchiveRoutes,
  EntrustRoutes,
  UserRoutes
);

//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法, 以防止重复点击同一路由时控制台报错！
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0
      };
    }
  },
  routes
});

RouterInterceptor.before(router);
RouterInterceptor.after(router);

export default router;
