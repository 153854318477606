import { translateText } from '@/lang';

const Layout = () => import('@/views/layout/index.vue');
export default [
  {
    path: '/system',
    name: 'System',
    component: Layout,
    children: [
      {
        path: 'resource',
        name: 'Resource',
        component: () => import('@/views/system/resource-list'),
        meta: {
          keepAlive: true,
          title: 'lang_auto_2d1d9149_c8e7_4c01_b7d4_b0550f7fd376'
        }
      },
      {
        path: 'auth',
        name: 'Auth',
        component: () => import('@/views/system/auth-list'),
        meta: {
          keepAlive: true,
          title: 'lang_auto_3f5689a1_aaa9_4577_8026_ac4ac2074da3'
        }
      },
      {
        path: 'i18n',
        name: 'I18n',
        component: () => import('@/views/system/i18n-list'),
        meta: {
          keepAlive: true,
          title: 'lang_auto_95843cd0_9b0f_4ee0_83dd_b7cbcc5ea7e7'
        }
      },
      {
        path: 'i18nLanguageType',
        name: 'I18nLanguageType',
        component: () => import('@/views/system/i18n-language-type-list'),
        meta: {
          keepAlive: true,
          title: 'lang_language_type'
        }
      },
      {
        path: 'role',
        name: 'Role',
        component: () => import('@/views/system/role-list'),
        meta: {
          keepAlive: true,
          title: 'lang_auto_d5ddc471_ecd7_47f7_80b7_35aca3c97372'
        }
      },
      {
        path: 'managementMenu',
        name: 'ManagementMenu',
        component: () => import('@/views/system/menu-list-management'),
        meta: {
          keepAlive: true,
          title: 'lang_management_menu'
        }
      },
      {
        path: 'userMenu',
        name: 'UserMenu',
        component: () => import('@/views/system/menu-list-user'),
        meta: {
          keepAlive: true,
          title: 'lang_user_menu'
        }
      },
      {
        path: 'sso',
        name: 'Sso',
        component: () => import('@/views/system/sso/sso-list'),
        meta: {
          keepAlive: true,
          title: 'lang_auto_sso_manage'
        }
      },
      {
        path: 'auth_assign',
        name: 'AuthAssign',
        component: () => import('@/views/system/auth-assign'),
        meta: {
          keepAlive: true,
          title: 'lang_auto_1e35bee3_e89e_421c_8fd8_f0c3bb1d9688'
        }
      }
    ],
    meta: {
      keepAlive: true,
      title: 'lang_auto_4ae84049_6a88_48d1_a4c3_0049701f4043'
    }
  }
];
