import HttpConstant from '@/const/HttpConstant';
import HttpUtil from '@/utils/HttpUtil';
import CryptoUtil from 'global-ui/packages/utils/CryptoUtil';
const PUBLIC_KEY: string =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCgKEtllxrAZ1izOvtQbsv89sTp1KwNiqe3zoVEWu49CV80CntN2P42xi+kwx+DvrJx75WW0LpXOKkuAUUoBWrBgMh+Kx5+XwasPX36WfWtQ3OpJzmUPTvDD60w/gVpVc1xCYzRGNqSP/OBZyT/SxJDitD4UpTranpklRsIlv/+vwIDAQAB';
export default {
  accessCode(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'auth/accessCode';
    params.password = CryptoUtil.setRsa(params.password, PUBLIC_KEY);
    return HttpUtil.post(url, params);
  },

  login(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'auth/login';
    return HttpUtil.post(url, params, { 'Content-Type': HttpConstant.FORM_DATA_REQ });
  },

  logout(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'auth/login/out';
    return HttpUtil.post(url, params, { 'Content-Type': HttpConstant.FORM_DATA_REQ });
  },

  token(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'login/accessToken';
    params.password = CryptoUtil.setRsa(params.password, PUBLIC_KEY);
    return HttpUtil.post(url, params);
  },

  //获取登录后的缓存信息
  getLoginCache(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'login/identity';
    return HttpUtil.get(url, params);
  },
    
  // 获取单个人员
  getCurrentUser(){
    let url = process.env.VUE_APP_SERVER_CODE + 'auth/query/current/user';
    return HttpUtil.get(url);
  }
};
