<template>
  <!-- 重点展示 -->
  <div class="page-mask">
    <div class="page-loading">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlbLoading',
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
  destroyed() {}
};
</script>

<style lang="scss" scoped>
.page-mask {
  position: absolute;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  .page-loading {
    top: 50%;
    left: 50%;
    position: absolute;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    z-index: 100;
    width: 50px;
    height: 60px;
    font-size: var(--rootFontSize4);
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      /* background-color: #FE3C71; */
      background-color: #008df7;
      height: 100%;
      width: 6px;
      margin-right: 5px;
      -webkit-animation: stretchDelay 3s infinite ease-in-out;
      animation: stretchDelay 3s infinite ease-in-out;
    }
    .rect2 {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }
    .rect3 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }

    .rect4 {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }

    .rect5 {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }
  }
}

@-webkit-keyframes stretchDelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes stretchDelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
</style>
