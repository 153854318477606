var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        visible: _vm.visible,
        "close-on-click-modal": false,
        title: _vm.$t("lang_lang_setting"),
      },
      on: { close: _vm.closeDialog },
    },
    [
      _c(
        "el-table",
        { attrs: { "empty-text": _vm.$t("lang_no_data"), data: _vm.langs } },
        [
          _c("el-table-column", {
            attrs: { label: _vm.$t("lang_language"), prop: "language" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(_vm.parseType(row.id)))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("lang_text") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      model: {
                        value: row.content,
                        callback: function ($$v) {
                          _vm.$set(row, "content", $$v)
                        },
                        expression: "row.content",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: { "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v(_vm._s(_vm.$t("lang_cancel_"))),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addLanguage } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }