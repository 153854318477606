import Vue from 'vue';
import VueI18n from 'vue-i18n';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import _ from 'lodash';

// elementUi多语言
import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';
import elementFrLocale from 'element-ui/lib/locale/lang/fr';
import elementLocale from 'element-ui/lib/locale';

// TODO 如果后端语言类型新增了需要添加或者修改这里
let elementUiI18n = {
  zh: elementZhLocale,
  en: elementEnLocale,
  fr: elementFrLocale
};

// vxe-table 多语言
import vxeTableI18nZhCN from 'vxe-table/lib/locale/lang/zh-CN';
import vxeTableI18nEnUS from 'vxe-table/lib/locale/lang/en-US';

// TODO 如果后端语言类型新增了需要添加或者修改这里
let vxeTableI18n = {
  zh: vxeTableI18nZhCN,
  en: vxeTableI18nEnUS,
  fr: vxeTableI18nEnUS
};

// 用户选择的语言 如果不存在则存入cookie
export const curLangStorageKey = 'langCode';
let langCode = StorageUtil.getLocalStorage(curLangStorageKey);
if (!langCode) {
  langCode = 'zh';
  StorageUtil.setLocalStorage(curLangStorageKey, langCode);
}

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: langCode,
  messages: _.merge(elementUiI18n, vxeTableI18n)
});

elementLocale.i18n((key, value) => i18n.t(key, value));

// localStorage 语言包缓存key
export const langStorageKey = 'langs';
export const langStorageMd5Key = 'langMD5';
export const langTypeStorageKey = 'langTypes';

/**
 * 更换i18n语言
 * @param lang
 */
export function setI18nLanguage(lang) {
  i18n.locale = lang;
  return lang;
}

/**
 * 清理i18n浏览器缓存
 */
export function clearI18nLocalStorageCache(): void {
  localStorage.removeItem(langStorageKey);
  localStorage.removeItem(langTypeStorageKey);
  localStorage.removeItem(langStorageMd5Key);
}

/**
 * 设置cookie
 */
export function setI18nCookie(code): void {
  StorageUtil.setLocalStorage(curLangStorageKey, code);
}

// 翻译文本
export function translateText(text: string): string {
  return i18n.t(text) as any;
}

export default i18n;
