import { PageData, PageModel } from '../common/CommonModel';

export const WidgetDataSourceTypeEnum: Record<string, string> = {
  COMPLEX: 'COMPLEX',
  SIMPLE: 'SIMPLE'
};

export const WidgetDataSourceConfigTypeEnum: Record<string, string> = {
  GROUP_CONFIG: 'GROUP_CONFIG',
  USER_CONFIG: 'USER_CONFIG',
  ROLE_CONFIG: 'ROLE_CONFIG',
  APP_CONFIG: 'APP_CONFIG',
  PROCESS_INSTANCE_CONFIG: 'PROCESS_INSTANCE_CONFIG',
  ARCHIVES_CONFIG: 'ARCHIVES_CONFIG', //归档
  PROCESS_TASK_LOG_CONFIG: 'PROCESS_TASK_LOG_CONFIG',
  OPTION_CONFIG: 'OPTION_CONFIG',
  FORM_CONFIG: 'FORM_CONFIG',
  PROCESS_CONFIG: 'PROCESS_CONFIG',
  GROUP_USER_CONFIG: 'GROUP_USER_CONFIG',
  // zaa新增 列表组件类型
  KNOWLEDGE_SHARING_CONFIG: 'KNOWLEDGE_SHARING_CONFIG',
  MODULE_CONTENT_CONFIG: 'MODULE_CONTENT_CONFIG'
};

export interface WidgetDataSourceConditionModel {
  key: string;
  source: string;
  value: unknown;
  result: unknown;
  filter: any;
  compare: string;
  logical: string;
  first: boolean;
  dataSource: any[];
  tableName: string;
}

export const WidgetDataSourceConditionData: WidgetDataSourceConditionModel = {
  key: '',
  source: 'SELECT',
  value: null,
  result: null,
  filter: { type: 'STRING' },
  compare: '',
  logical: 'AND',
  first: true,
  dataSource: [],
  tableName: ''
};
export interface WidgetDataSourceConditionGroupModel {
  key: string;
  logical: string;
  conditions: WidgetDataSourceConditionModel[];
  first: boolean;
}

export const WidgetDataSourceConditionGroupData = [
  {
    key: 'group1',
    logical: 'AND',
    conditions: [WidgetDataSourceConditionData],
    first: true
  }
];

export interface WidgetDataSourceConditionConfigModel {
  conditions: any[];
  dataListConditions: any[];
}

export const WidgetDataSourceConditionConfigData: WidgetDataSourceConditionConfigModel = {
  conditions: [],
  dataListConditions: []
};

export interface WidgetDataSourceColumnModel {
  title: string;
  key: string | null;
  value: any;
  index: number;
  display: boolean;
  visible: boolean;
  val: boolean;
  html: boolean;
  search: boolean;
}

export const WidgetDataSourceColumnData: WidgetDataSourceColumnModel = {
  title: '',
  key: null,
  value: {},
  index: 0,
  display: false,
  visible: false,
  val: false,
  html: false,
  search: false
};

export interface WidgetDataSourceColumnConfigModel {
  defaultSimpleColumn: WidgetDataSourceColumnModel;
  defaultComplexColumns: WidgetDataSourceColumnModel[];
  customSimpleColumn: WidgetDataSourceColumnModel;
  customComplexColumns: WidgetDataSourceColumnModel[];
  orderConfigs: any[];
}

export const WidgetDataSourceColumnConfigData: WidgetDataSourceColumnConfigModel = {
  defaultSimpleColumn: WidgetDataSourceColumnData,
  defaultComplexColumns: [],
  customSimpleColumn: WidgetDataSourceColumnData,
  customComplexColumns: [],
  orderConfigs: []
};

export interface WidgetDataSourceConfigModel {
  // 公共属性
  name: string;
  type: string;
  columnConfig?: WidgetDataSourceColumnConfigModel;
  conditionConfig?: WidgetDataSourceConditionConfigModel;

  // 静态配置-属性
  normalDataList?: any[];

  // 表单数据-属性
  formCode: string | null;
  configurable?: boolean;
  dataLevel?: string;

  // 意见数据源配置
  processCode?: string | null;
  lastNode?: boolean;
  lastOperator?: boolean;
  id?: string;
  related?: Set<string>;

  scopeType?: any;
  scope?: any;
}

// conditions: any[];
// 动态配置-属性
// dataListConditions: WidgetDataSourceConditionGroupModel[];
export const WidgetDataSourceConfigData: WidgetDataSourceConfigModel = {
  type: '',
  name: '',
  conditionConfig: WidgetDataSourceConditionConfigData,
  columnConfig: WidgetDataSourceColumnConfigData,

  // 静态配置-属性
  normalDataList: [],

  // 表单数据-属性
  formCode: null,
  configurable: false

  // processCode: null,
  // lastNode: false,
  // lastOperator: false
};

export const WidgetDataSourceConfigGroups = {
  NORMAL: '普通类',
  DYNAMIC: '动态类',
  INTERFACE: '接口类'
};

export interface FactorModel {
  id: string;
  type: string;
  value: any;
  label: string;
  children: FactorModel[];
}

export const FactorData: FactorModel = {
  id: '',
  type: '',
  value: '',
  label: '',
  children: []
};

export const WidgetDefaultDataConfigType = {
  FORM_DATA: 'FORM_DATA',
  FORMULA: 'FORMULA',
  FORM_ARGUMENT: 'FORM_ARGUMENT',
  MULTIPLE_DATA: 'MULTIPLE_DATA',
  SINGLE_DATA: 'SINGLE_DATA'
};

export interface WidgetDefaultDataConfigModel {
  type: string;
  formula: FactorModel[];
  simpleDefaultDataList: any[];
  complexDefaultDataList: any[];
  argumentList: any[];
  order: number;
  valueType: string;
}

export const WidgetDefaultDataConfigData: WidgetDefaultDataConfigModel = {
  type: '',
  formula: [],
  simpleDefaultDataList: [],
  complexDefaultDataList: [],
  argumentList: [],
  order: 0,
  valueType: ''
};

export interface WidgetDataSourceModel {
  type: string;
  dataList: any[];
  defaultValue: any[];
  dataSourceConfig: WidgetDataSourceConfigModel;
  defaultDataConfig?: WidgetDefaultDataConfigModel;
  pageInfo: PageModel;
  searchParams: any;
}

export const WidgetDataSourceData: WidgetDataSourceModel = {
  type: 'SIMPLE',
  dataList: [],
  defaultValue: [],
  dataSourceConfig: WidgetDataSourceConfigData,
  pageInfo: PageData,
  defaultDataConfig: WidgetDefaultDataConfigData,
  searchParams: {}
};
