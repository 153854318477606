import { translateText } from '@/lang';

const Layout = () => import('@/views/layout/index.vue')
export default [{
  path: "/log",
  name: "Log",
  component: Layout,
  children: [{
      path: "request",
      name: "Resource",
      component: () => import("@/views/logs/request-log"),
      meta: {
        keepAlive: true,
        title: translateText('lang_auto_4fd06382_0edc_4817_8eca_893f0d5bd5d1')
      }
    },
    {
      path: "backend",
      name: "Backend",
      component: () => import("@/views/logs/backend-log"),
      meta: {
        keepAlive: true,
        title: translateText('lang_auto_98453d0d_eca7_44ac_8e6d_5698b151e2d6')
      }
    },
  ],
  meta: {
    keepAlive: true,
    title: translateText('lang_auto_b68c8588_015c_4742_96a7_961a953bef6e')
  }
}, ];
