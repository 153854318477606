import Vue from 'vue';
import App from './App.vue';
import router from './router/RouterIndex';
import StoreIndex from './store/StoreIndex';

import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './directives/index'; //引入全局vue指令
import GlobalUi from 'global-ui';
// import 'global-ui/packages/styles/common.scss';
import LangInput from './components/lang-config/input.vue';
import './assets/css/common.scss';
import './assets/css/elementui.scss';
import './assets/css/theme/base.scss'; //更换主题css
import './assets/css/iconfont.scss';
// import jquery from "jquery"

// 多语言
import i18n from '@/lang';

//请执行npm install @jiaminghi/data-view
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)
Vue.use(VXETable, {
  i18n: (key, value) => i18n.t(key, value)
});

Vue.config.productionTip = false;

Vue.component('treeselect', Treeselect);

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});
// Vue.prototype.$ = jquery
Vue.use(GlobalUi);
Vue.component('LangInput', LangInput);

let app = new Vue({
  i18n,
  router,
  store: StoreIndex,
  render: h => h(App)
});
app.$mount('#app');
export default app;
