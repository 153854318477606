import axios from 'axios';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import StoreIndex from '@/store/StoreIndex';
import { Message } from 'element-ui';

//默认请求响应超时时间
axios.defaults.timeout = 3000 * 60;
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;

//HTTPrequest拦截
axios.interceptors.request.use(
  config => {
    // 设置token
    config.headers['Authorization'] = StorageUtil.getSessionStorage('token');
    config.headers['language'] = StorageUtil.getLocalStorage('langCode') || 'zh';
    if (config.loading != false) {
      StoreIndex.commit('ADD_ACTIVED_THREAD');
    }
    // config.headers["Authorization"] = process.env.VUE_APP_TOKEN
    return config;
  },
  error => {
    console.log('err' + error); // for debug
    return Promise.reject(error);
  }
);
//HTTPresponse拦截
axios.interceptors.response.use(
  res => {
    if (res.data.code == '-1') {
      // 如果code是-1，则是token失效，返回登录页
      StorageUtil.clearSessionStorage();
      let loginType = StoreIndex.getters.loginType;
      if (loginType === '2') {
        StoreIndex.commit('SUB_ACTIVED_THREAD');
      } else {
        let url = `${process.env.VUE_APP_SERVER_CODE}auth/login?clientId=${process.env.VUE_APP_CLIENT_ID}&redirectUrl=${encodeURIComponent(
          window.location.href
        )}`;
        setTimeout(() => {
          window.location.href = url;
        }, 1000);
      }
      return res.data;
    }
    if (res.config.loading != false) {
      StoreIndex.commit('SUB_ACTIVED_THREAD');
    }
    return res.data;
  },
  error => {
    if (error.config.loading != false) {
      StoreIndex.commit('SUB_ACTIVED_THREAD');
    }
    Message.error(`（${error.response.data.code}）${error.response.data.message}`);
    return Promise.reject(error.response.data);
  }
);

export default axios;
