import StorageUtil from 'global-ui/packages/utils/StorageUtil';

let HomeStore = {
  namespaced: true,
  state: {
    todoList: [],
    langVisible: false,
    currentCode: {},
    input: null,
    lang: StorageUtil.getLocalStorage('langCode')
  },
  mutations: {
    todo(state, data) {
      state.todoList = data;
    },
    SET_VIS(state, data) {
      state.langVisible = data;
    },
    SET_CODE(state, data) {
      state.currentCode = data;
    },
    SET_INPUT(state, data) {
      state.input = data;
    },
    SET_LANG(state, data) {
      state.lang = data;
    }
  },
  actions: {
    changeVisible({ commit }, data) {
      commit('SET_VIS', data);
    },
    changeCurrentCode({ commit }, data) {
      commit('SET_CODE', data);
    },
    changeLang({ commit }, data) {
      commit('SET_LANG', data);
    },
    editInput({ commit }, data) {
      commit('SET_INPUT', data);
    }
  }
};
export default HomeStore;
