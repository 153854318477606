import {
  GraphNodeData
} from '@/models/flow/GraphNodeModel';

import {
  FlowData
} from '@/models/flow/FlowModel';

import SystemUtil from 'global-ui/packages/utils/SystemUtil';
const flow = {
  state: {
    flowConfig: SystemUtil.cloneDeep(FlowData),
    activedNode: SystemUtil.cloneDeep(GraphNodeData),
    activedLogNode: {},
    currentTask: {},
    processLogs: [],
    processInstance: {},
    taskHistories: [],
    processRuntime: {},
  },
  mutations: {
    SET_FLOW_CONFIG(state, data) {
      state.flowConfig = data;
    },
    SET_ACTIVED_NODE(state, data) {
      state.activedNode = data;
    },
    SET_ACTIVED_LOG_NODE(state, data) {
      state.activedLogNode = data;
    },
    SET_CURRENT_TASK(state, data) {
      state.currentTask = data;
    },
    SET_PROCESS_INSTANCE(state, data) {
      state.processInstance = data;
    },
    SET_PROCESS_RUNTIME(state, data) {
      state.processRuntime = data;
    },
    SET_TASK_HISTORIES(state, data) {
      state.taskHistories = data;
    },
    SET_PROCESS_LOGS(state, data) {
      state.processLogs = data;
    }
  },
  getters: {
    /**
     * @description: 获取当前表单配置
     * @return:
     */
    flowConfig: state => {
      return state.flowConfig;
    },

    // 获取当前选中的组件
    activedNode: state => {
      return state.activedNode;
    },

    // 获取当前选中的组件
    activedLogNode: state => {
      return state.activedLogNode;
    },

    // 获取当前选中的组件
    currentTask: state => {
      return state.currentTask;
    },

    // 获取当前选中的组件
    processLogs: state => {
      return state.processLogs;
    },
    processInstance: state => {
      return state.processInstance;
    },
    processRuntime: state => {
      return state.processRuntime;
    },
    taskHistories: state => {
      return state.taskHistories || [];
    },
  }
};

export default flow;