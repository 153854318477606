import qs from 'qs';
import axios from "@/interceptor/HttpInterceptor";
import HttpConstant from "@/const/HttpConstant";
import TokenUtil from 'global-ui/packages/utils/TokenUtil'
export default {
  /**
   *
   * 自定义get请求
   *
   */
  get: (url, params, config) => { 
    if (!params) {
      params = {};
    }
    if (!config) {
      config = {};
    }
    return axios.get(url, {
      params: params
    }, config);
  },

  /**
   *
   * 自定义post请求
   *
   */
  post: (url, params, config) => {
    if (!params) {
      params = {};
    }
    if (!config) {
      config = {};
    } else if (config["Content-Type"] == HttpConstant.FORM_DATA_REQ) {
      params = qs.stringify(params);
    }
    return axios.post(url, params, config);
  },

  /**
   *
   * 自定义文件上传方法
   *
   */
  upload: (url, params, config) => {
    if (!config) {
      config = axios.defaults.headers;
    }
    let configTmp = Object.assign({}, config);
    configTmp["timeout"] = 1000 * 60 * 5;
    configTmp["Content-Type"] = HttpConstant.MUL_FORM_REQ;
    configTmp["Authorization"] = TokenUtil.getToken();
    let formData = new FormData(); //创建form对象

    for (let field in params) {
      if (params[field]) {
        formData.append(field, params[field]);
      }
    }
    return axios.post(url, formData, configTmp);
  },

  /**
   *
   * 自定义文件下载方法
   *
   */
  download: (url, params, config) => {
    var paramUrl = "?";
    for (let field in params) {
      if (params[field]) {
        paramUrl = paramUrl + field + "=" + params[field] + "&";
      }
    }
    let token = TokenUtil.getToken();
    paramUrl += `token=${token}`
    console.log(url + paramUrl);
    window.location.href = url + paramUrl;
  },

  /**
   *
   * 自定义文件预览方法
   *
   */
  preview: (url, params, config) => {
    let paramUrl = "?";
    for (let field in params) {
      if (params[field]) {
        paramUrl = paramUrl + field + "=" + params[field] + "&";
      }
    }
    let token = TokenUtil.getToken();
    paramUrl += `token=${token}#toolbar=0`
    window.open(url + paramUrl);
  }

}