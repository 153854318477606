import { translateText } from '@/lang';

const Layout = () => import('@/views/layout/index.vue');
export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
    meta: {
      keepAlive: true,
      title: '登录'
    }
  },
  {
    path: '/printer',
    name: 'login',
    component: () => import('@/views/form-design/form-printer'),
    meta: {
      keepAlive: true,
      title: '登录'
    }
  },
  {
    path: '/zaa',
    name: 'zaa',
    component: () => import('@/components/tinymce-editer/index'),
    meta: {
      keepAlive: true,
      title: 'zaa'
    }
  },
  {
    path: '/front2',
    component: Layout,
    children: [
      {
        path: 'waitList',
        component: () => import('@/views/front-frame/front-wait-list2.0'),
        meta: {
          keepAlive: true,
          title: 'lang_to_do_list'
        }
      },
      {
        path: 'doneList',
        component: () => import('@/views/front-frame/front-done-list2.0'),
        meta: {
          keepAlive: true,
          title: 'lang_done_items'
        }
      },
      {
        path: 'possibilityList',
        component: () => import('@/views/front-frame/front-possibility-list2.0'),
        meta: {
          keepAlive: true,
          title: 'lang_predicted_events'
        }
      },
      {
        path: 'startedList',
        component: () => import('@/views/front-frame/front-started-list2.0'),
        meta: {
          keepAlive: true,
          title: 'lang_initiating_matters'
        }
      },
      {
        path: 'create',
        component: () => import('@/views/home/flow2/flow-create'),
        meta: {
          keepAlive: true,
          title: 'lang_new_process'
        }
      }
    ],
    meta: {
      keepAlive: true,
      title: 'lang_my_mission'
    }
  },

  {
    path: '/front2/start',
    name: 'UserHomeFlowStart',
    component: () => import('@/views/home/flow2/flow-start'),
    meta: {
      keepAlive: true,
      title: 'lang_process_start'
    }
  },
  {
    path: '/front2/request',
    name: 'UserHomeFlowRequest',
    component: () => import('@/views/home/flow2/flow-request'),
    meta: {
      keepAlive: true,
      title: 'lang_process_start'
    }
  },
  {
    path: '/front2/undo',
    name: 'UserHomeFlowUndo',
    component: () => import('@/views/home/flow2/flow-undo'),
    meta: {
      keepAlive: true,
      title: 'lang_to_do_list'
    }
  },
  {
    path: '/front2/archive',
    name: 'UserHomeFlowArchive',
    component: () => import('@/views/home/flow2/flow-archive'),
    meta: {
      keepAlive: true,
      title: 'lang_archive_item'
    }
  },
  {
    path: '/front2/done',
    name: 'UserHomeFlowDone',
    component: () => import('@/views/home/flow2/flow-done'),
    meta: {
      keepAlive: true,
      title: 'lang_done_items'
    }
  },
  {
    path: '/front2/possibility',
    name: 'UserHomeFlowPossibility',
    component: () => import('@/views/home/flow2/flow-possibility'),
    meta: {
      keepAlive: true,
      title: 'lang_predicted_events'
    }
  },
  {
    path: '/front',
    name: 'FrontFrame',
    component: () => import('@/views/front-frame/index'),
    redirect: '/front/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/front-frame/placeholder'),
        meta: {
          keepAlive: true,
          title: 'lang_my_mission'
        }
      },
      {
        path: 'create',
        name: 'UserHomeFlowCreate',
        component: () => import('@/views/home/flow/flow-create'),
        meta: {
          keepAlive: true,
          title: translateText('lang_new_process')
        }
      },
      {
        path: 'start',
        name: 'UserHomeFlowStart',
        component: () => import('@/views/home/flow/flow-start'),
        meta: {
          keepAlive: true,
          title: 'lang_process_start'
        }
      },
      {
        path: 'undo',
        name: 'UserHomeFlowUndo',
        component: () => import('@/views/home/flow/flow-undo'),
        meta: {
          keepAlive: true,
          title: 'lang_to_do_list'
        }
      },
      {
        path: 'done',
        name: 'UserHomeFlowDone',
        component: () => import('@/views/home/flow/flow-done'),
        meta: {
          keepAlive: true,
          title: 'lang_done_items'
        }
      },
      {
        path: 'possibility',
        name: 'UserHomeFlowPossibility',
        component: () => import('@/views/home/flow/flow-possibility'),
        meta: {
          keepAlive: true,
          title: 'lang_predicted_events'
        }
      }
    ]
  },
  {
    path: '/flow-reader',
    name: 'flowReader',
    component: () => import('@/views/flow-design/flow-readonly'),
    meta: {
      keepAlive: true,
      title: '查看流程'
    }
  },
  {
    path: '/seal',
    name: 'FlowSeal',
    component: () => import('@/components/file-seal/file-seal'),
    meta: {
      keepAlive: true,
      title: '签章'
    }
  },
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index'),
        meta: {
          keepAlive: true,
          title: 'lang_auto_b99db43d_d860_4ad2_88d6_42833aa0fb57'
        }
      }
    ],
    meta: {
      keepAlive: true,
      title: '布局页面'
    }
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/components/other-page/403'),
    meta: {
      title: '权限不足'
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/components/other-page/404'),
    meta: {
      title: '找不到页面'
    }
  }
];
