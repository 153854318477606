import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import LoginApi from '@/api/system/LoginApi';
import { getFunctions, getWidgetConfigStruct, getDataSourceMapping, getBusinessDataSource, getCompares } from '@/api/form-design/FormApi';
import { getLanguageResourceQueryI18n, getLanguageTypeQueryList, languageResourceQueryI18nChecksum } from '@/api/system/LanguageApi';
import { setI18nCookie, setI18nLanguage, langStorageKey, langStorageMd5Key, langTypeStorageKey } from '@/lang';
import StoreIndex from '../store/StoreIndex';
import i18n from '@/lang';
import { getWsUrl } from '@/api/system/AssistApi';
import { getUserCompany } from '@/api/system/OrganizationApi';
let _ = require('lodash');
const whiteRoutes = ['/login', '/404', '/500'];
export default {
  /**
   * @description: 路由跳转前操作
   * @param {}
   * @return:
   */
  before(router) {
    router.beforeEach(async (to, from, next) => {
      if (to.query.language) {
        setI18nCookie(to.query.language);
        setI18nLanguage(to.query.language);
      }
      let loginType = '1';
      StoreIndex.commit('SET_LOGIN_TYPE', loginType);
      if (whiteRoutes.includes(to.path)) {
        await this.setLangCache();
        await this.setWsUrlCache();
        next();
      } else {
        //判断登录方式
        // let loginTypeRes = await LoginApi.getLoginWay({});
        // let loginType = loginTypeRes.data;
        let token = StorageUtil.getSessionStorage('token');
        if (token) {
          let isInited = StoreIndex.state.common.isInited;
          if (!isInited) {
            // 如果是刷新页面，则重新加载缓存
            await this.reloadCache();
            StoreIndex.commit('SET_LOAD_STATUS', true);
          }
          next();
        } else {
          if (loginType === '1') {
            this.ssoHandler(to, from, next);
          } else {
            next('/login');
          }
        }
      }
    });
  },

  /**
   * @description: 路由跳转后操作
   * @param {}
   * @return:
   */
  after(router) {
    router.afterEach((to, from, next) => {
      if (to.meta.title) {
        document.title = i18n.t(to.meta.title);
      }
    });
  },

  /**
   * @description: 处理单点登录
   * @param {type}
   * @return:
   */
  async ssoHandler(to, from, next) {
    //判断ssoToken地址栏是否携带
    if (to.query.token) {
      StorageUtil.setSessionStorage('token', to.query.token);
      await this.reloadCache();
      delete to.query.token;
      delete to.query.clientId;
      // next()
      next({
        path: to.path,
        query: to.query
      });
    } else {
      let url = `${process.env.VUE_APP_SERVER_CODE}auth/login?clientId=${process.env.VUE_APP_CLIENT_ID}&redirectUrl=${encodeURIComponent(
        window.location.href
      )}`;
      window.location.href = url;
    }
  },

  //重新加载系统公共资源
  reloadCache() {
    return new Promise(async (resolve, reject) => {
      // 获取登录后的用户缓存
      let userCache = (await LoginApi.getLoginCache({ type: '1' })).data;
      if (userCache) {
        sessionStorage.setItem('menuList', JSON.stringify(userCache))
        // 存储个人信息
        let userInfo = userCache.user;
        userInfo.tenants = userCache.tenants;
        StoreIndex.commit('SET_USER', userInfo);
        // 存储菜单
        StoreIndex.commit('SET_MENUS', userCache.menus);

        //获取当前公司信息
        let params = {
          tenantId: userInfo.tenantId,
          userId: userInfo.id
        };
        let companyData = (await getUserCompany(params)).data;
        if (companyData) {
          // 存储公司信息
          StoreIndex.commit('SET_COMPANY_DATA', companyData[0]);
        } else {
          resolve('companyData');
        }
      } else {
        resolve('00001');
      }

      // 加载多语言
      let langCache = await this.setLangCache();
      if (langCache) {
        resolve('00000');
      } else {
        resolve('00002');
      }

      let wsCache = await this.setWsUrlCache();
      if (wsCache) {
        resolve('00000');
      } else {
        resolve('00002');
      }
      // 获取流程引擎、表单引擎相关数据
      let folwCache = await this.getEngineCacha();
      if (folwCache) {
        resolve('00000');
      } else {
        resolve('00002');
      }

      //获取主题色
      // let themeRes = await LoginApi.themeDetail({})
      let themeRes = {
        success: true,
        code: '00000',
        message: '请求成功',
        data: {
          createTime: null,
          createAccountId: null,
          updateTime: null,
          updateAccountId: null,
          systemThemeId: '1352509947693961217',
          themeCode: 'WarmOrange',
          themeSort: null,
          themeName: '温暖橙',
          flagDefault: 1,
          mainColor: '#409EFF',
          auxiliaryColor: '#409EFF',
          backgroundColor: '#409EFF',
          characterColor: '#7C665A',
          field1: '#FF0000',
          field2: null,
          field3: null,
          field4: null,
          namePinyinAbbrevia: null,
          namePinyin: null
        }
      };
      StorageUtil.setSessionStorage('theme', themeRes.data);
      this.setTheme(themeRes.data);

      resolve('00000');
    });
  },

  //设置主题样式
  setTheme(themeObj) {
    document.documentElement.style.setProperty('--mainThemeColor', themeObj.mainColor);
    document.documentElement.style.setProperty('--secondThemeColor', themeObj.auxiliaryColor);
    document.documentElement.style.setProperty('--threeThemeColor', themeObj.backgroundColor);
    document.documentElement.style.setProperty('--fourThemeColor', themeObj.characterColor);
    document.documentElement.style.setProperty('--themeCode', themeObj.themeCode);
  },

  getEngineCacha() {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.loadFormFunctions(),
        this.loadFormCompares(),
        this.loadWidgetConfigStruct(),
        this.loadDataSourceMapping(),
        this.loadStaticDataSource()
      ])
        .then(result => {
          resolve(true);
        })
        .catch(error => {
          resolve(false);
        });
    });
  },

  loadFormFunctions() {
    return new Promise((resolve, reject) => {
      getFunctions().then(res => {
        if (res.code == '1') {
          StoreIndex.commit('SET_FORM_FUNCTIONS', res.data);
          resolve(res.data);
        } else {
          reject('functions');
        }
      });
    });
  },
  loadFormCompares() {
    return new Promise((resolve, reject) => {
      getCompares().then(res => {
        if (res.code == '1') {
          StoreIndex.commit('SET_FORM_COMPARES', res.data);
          resolve(res.data);
        } else {
          reject('compares');
        }
      });
    });
  },
  loadWidgetConfigStruct() {
    return new Promise((resolve, reject) => {
      getWidgetConfigStruct().then(res => {
        if (res.code == '1') {
          StoreIndex.commit('SET_WIDGET_CONFIG_STRUCT', res.data);
          resolve(res.data);
        } else {
          reject('widgetConfigStruct');
        }
      });
    });
  },
  loadDataSourceMapping() {
    return new Promise((resolve, reject) => {
      getDataSourceMapping().then(res => {
        if (res.code == '1') {
          StoreIndex.commit('SET_DATASOURCE_MAPPING', res.data);
          resolve(res.data);
        } else {
          reject('dataSourceMapping');
        }
      });
    });
  },
  // 获得表单数据源配置时的 业务数据类型的 选项
  loadStaticDataSource() {
    return new Promise((resolve, reject) => {
      getBusinessDataSource().then(res => {
        if (res.code == '1' && res.data) {
          StoreIndex.commit('SET_BUSINESS_DATA_SOURCE', res.data);
          resolve(res.data);
        } else {
          reject('dataSource');
        }
      });
    });
  },

  // 缓存多语言包
  setLangCache() {
    return new Promise(async (resolve, reject) => {
      let isUpdate = await this.checkLangStatus();
      if (isUpdate) {
        let langCache = await this.getLangCache();
        if (langCache) {
          resolve(true);
        }
      } else {
        let langData = StorageUtil.getLocalStorage(langStorageKey);
        if (langData) {
          for (const langCode in langData) {
            const langMessages = langData[langCode];
            i18n.mergeLocaleMessage(langCode, langMessages);
          }
        }
        // 如果 拿不到langData 再次请求多语言包
        else {
          let langCache = await this.getLangCache();
          if (langCache) {
            resolve(true);
          }
        }
      }
      resolve(false);
    });
  },
  // 判断语言包是否更新
  checkLangStatus() {
    return new Promise((resolve, reject) => {
      languageResourceQueryI18nChecksum().then(res => {
        if (res.code == '1') {
          let langMd5 = res.data;
          let cacheLangMd5 = StorageUtil.getLocalStorage(langStorageMd5Key);
          if (cacheLangMd5 !== langMd5) {
            StorageUtil.setLocalStorage(langStorageMd5Key, langMd5);
            resolve(true);
          } else {
            resolve(false);
          }
        }
      });
    });
  },
  // 获取语言包缓存
  getLangCache() {
    return new Promise((resolve, reject) => {
      let params = { staticFlag: false };
      let params2 = { staticFlag: true };
      Promise.all([getLanguageResourceQueryI18n(params), getLanguageResourceQueryI18n(params2), getLanguageTypeQueryList()])
        .then(([res1, res2, res3]) => {
          if (res1.code == '1' && res2.code == '1' && res3.code == '1') {
            res3.data.forEach(item => {
              let langMessages = {
                ...res1.data[item.code],
                ...res2.data[item.code]
              };
              i18n.mergeLocaleMessage(item.code, langMessages);
            });
            StorageUtil.setLocalStorage(langStorageKey, i18n.messages);
            StorageUtil.setLocalStorage(langTypeStorageKey, res3.data);
          }
          resolve(true);
        })
        .catch(error => {
          resolve(false);
        });
    });
  },

  // 缓存websocket url
  setWsUrlCache() {
    return new Promise(async (resolve, reject) => {
      let wsUrl = StorageUtil.getLocalStorage('wsUrl');
      if (wsUrl) {
        resolve(true);
      } else {
        getWsUrl()
          .then(res => {
            if (res.code == '1') {
              StorageUtil.setLocalStorage('wsUrl', res.data);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(e => {
            resolve(false);
          });
      }
    });
  }
};
